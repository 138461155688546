import { useHistory } from "react-router-dom"
import { NextBtnWithArrow } from "../carDetails";
import { FormOne } from "./forms/formOne";
import { FormEvent, useEffect, useState } from "react";
import { ApplyFormObj, formInputPros } from "../../includes/types";
import { FormTwo } from "./forms/formTwo";
import { FormThree } from "./forms/formThree";
import { FormFour } from "./forms/formFour";
import { FormFive } from "./forms/formFive";
import useHttpHook from "../../includes/useHttpHook";
import {toast} from "react-toastify"
import HeaderComponent from "../../components/header";
const ApplyToDrive = ()=>{
    const history = useHistory();
    const {loading,HandleApplySubmit} = useHttpHook()
    const [formSection,setFormSection] = useState<number>(1);
    const [formData,setFormData] = useState<ApplyFormObj>({
    aboveAge:"",
    city:"",
    country:"",
    creditScore:"",
    email:"",
    firstName:"",
    hasDriverLicense:"",
    knowsCreditScore:"",
    lastName:"",
    needHelpGettingDriverLicence:"",
    phoneNumber:"",
    postCode:"",
    province:"",
    startDate:"",
    typeOfArrangement:"",
    validDriversLicense:"",
    livingStatus:"",
    workStatus:"",
    downPayment:"0"
    });
const handleFormData = (prop:formInputPros)=>{
    setFormData({...formData,
        [prop.key]:prop.value
    })
}
const handleSubmitForm = (e:FormEvent)=>{
e.preventDefault();
if(formSection === 1)
{
return setFormSection(formSection + 1)
}
if(formSection === 2)
{
return setFormSection(formSection + 1)
}
if(formSection === 3)
{
return setFormSection(formSection + 1)
}
if(formSection === 3)
{
 return setFormSection(formSection + 1)
}
if(formSection === 4)
    {
     return setFormSection(formSection + 1)
    }
HandleApplySubmit(formData).then((res)=>{
 if(res.status)
{
toast.success(res.message,{position:"top-center"})
setFormData({
    aboveAge:"",
    city:"",
    country:"",
    creditScore:"",
    email:"",
    firstName:"",
    hasDriverLicense:"",
    knowsCreditScore:"",
    lastName:"",
    needHelpGettingDriverLicence:"",
    phoneNumber:"",
    postCode:"",
    province:"",
    startDate:"",
    typeOfArrangement:"",
    validDriversLicense:"",
    livingStatus:"",
    workStatus:"",
    downPayment:"0"
    })
return setFormSection(1)
    }else{
        toast.error(res.message,{position:"top-center"})
    }
})

}
useEffect(()=>{
    window.scrollTo({top:0,behavior:"smooth"})
},[])


    return <>
    <HeaderComponent black />
    <form 
    onSubmit={handleSubmitForm}
    >
    <div className="neutral">
   
       {formSection === 1 &&<FormOne 
       onValue={(d)=>handleFormData(d)}
       onBack={(d)=>{
        setFormSection(formSection - 1)
       }}
       values={formData}
       />}
        {formSection === 2 &&<FormTwo 
       onValue={(d)=>handleFormData(d)}
       onBack={(d)=>{
        setFormSection(formSection - 1)
       }}
       values={formData}
       />}
        {formSection === 3 &&<FormThree
       onValue={(d)=>handleFormData(d)}
       onBack={(d)=>{
        setFormSection(formSection - 1)
       }}
       values={formData}
       />}
         {formSection === 4 &&<FormFour
       onValue={(d)=>handleFormData(d)}
       onBack={(d)=>{
        setFormSection(formSection - 1)
       }}
       values={formData}
       />} 
       {formSection === 5 &&<FormFive
       onValue={(d)=>handleFormData(d)}
       onBack={(d)=>{
        setFormSection(formSection - 1)
       }}
       values={formData}
       loading={loading}
       />}  
    </div>
    </form>
    </>
}
export default ApplyToDrive;