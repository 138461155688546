export const AppRoutes = {
    Drive:"/drive",
    Faqs:"/faqs",
    Investors:"/investors",
    Apply:"/apply",
    GetStarted:"/get-started",
    Cars:"/cars",
    CarDetails:"/car/:id",
    GetIntorch:"/get-in-torch"
}
export const BaseUrlDev = "https://gear-up-be-jklaqbjaga-uc.a.run.app/v1/";
export const BaseUrlProduction = "https://gear-up.api.treepz.com/v1/";
export const SocialLinks = {
    x:"https://twitter.com/treepzglobal",
    facebook:"https://facebook.com/treepzglobal",
    linkedin:"https://www.linkedin.com/company/20535443/admin/feed/posts/",
    instagram:"https://www.instagram.com/treepzglobal",
    ticktok:""
}





export const CanadaProvinces = [
    // {title:"Alberta",value:"Alberta",list:[
    //     {title:"Leduc",value:""},
    //     {title:"Okotoks",value:""},
    //     {title:"Cold Lake",value:""},
    //     {title:"Brooks",value:""},
    //     {title:"Grand Centre",value:""},
    //     {title:"Strathmore",value:""},
    //     {title:"Canmore",value:""},
    //     {title:"Wetaskiwin",value:""},
    //     {title:"Sylvan Lake",value:""},
    //     {title:"Lacombe",value:""},
    //     {title:"Hinton",value:""},
    //     {title:"High River",value:""},
    //     {title:"Stony Plain",value:""},
    //     {title:"Whitecourt",value:""},
    //     {title:"Taber",value:""},
    //     {title:"Morinville",value:""},
    //     {title:"Edson",value:""},
    //     {title:"Innisfail",value:""},
    //     {title:"Slave Lake",value:""},
    //     {title:"Banff",value:""}
    // ]},
    {title:"British Columbia",value:"British Columbia",list:[
        {title:"Prince George",value:""},
        {title:"Port Coquitlam",value:""},
        {title:"Chilliwack",value:""},
        {title:"North Vancouver",value:""},
        {title:"Vernon",value:""},
        {title:"West Vancouver",value:""},
        {title:"Penticton",value:""},
        {title:"Mission",value:""},
        {title:"Campbell River",value:""},
        {title:"Courtenay",value:""},
        {title:"Duncan",value:""},
        {title:"Port Alberni",value:""},
        {title:"Parksville",value:""},
        {title:"Tsawwassen",value:""},
        {title:"Terrace",value:""},
        {title:"Cranbrook",value:""},
        {title:"Pitt Meadows",value:""},
        {title:"Squamish",value:""},
        {title:"Fort Saint John",value:""},
        {title:"Prince Rupert",value:""}
    ]},
      {title:"Ontario",value:"Ontario",list:[
        {title:"Prince George",value:""}
]},
    // {title:"Manitoba",value:"Manitoba"},
    // {title:"New Brunswick",value:"New Brunswick"},
    // {title:"Newfoundland and Labrador",value:"Newfoundland and Labrador"},
    // {title:"Nova Scotia",value:"Nova Scotia"},
    // {title:"Northwest Territories",value:"Northwest Territories"},
    // {title:"Nunavut",value:"Nunavut"},
  
    // {title:"Prince Edward Island",value:"Prince Edward Island"},
    // {title:"Québec",value:"Québec"},
    // {title:"Saskatchewan",value:"Saskatchewan"},
    // {title:"Yukon",value:"Yukon"}
]