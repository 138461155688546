import { FormOne } from "./forms";
import { SectionBlackComponent } from "../../components/footer/sectionTwo";
import HeaderComponent from "../../components/header";

const GetInTorchComponent = ()=>{
    return <>
    <HeaderComponent black  />
    <div className="neutral">
   <FormOne 
    />
    <SectionBlackComponent />  
    </div>
    </>
}
export default GetInTorchComponent;