import { HTMLInputTypeAttribute, RefObject, useEffect, useRef, useState } from "react";
import "./style.css";
import { ItemProps, formInputPros } from "../../includes/types";
interface BaseSelectProps {
onChange:(d:ItemProps)=>void;
value:string;
max?:number;
label?:string;
placeholder?:string;
required?:boolean;
name:string;
option?:ItemProps[]
}
const BaseSelect = (props:BaseSelectProps)=>{
    const [value,setValue] = useState<string>("");
    const [show,setShow] = useState<boolean>(false);
    const thisView = useRef() as RefObject<HTMLDivElement>
    useEffect(()=>{
        setValue(props.value)
        thisView.current?.addEventListener("mouseleave",()=>{
            setShow(false)
        })
    },[props.value,show])
    return <div className="mb-3 p-rel "
    ref={thisView}
    >
      
        {props?.label &&<label className="baseInput-label">{props.label}</label>}
    <div className="p-rel" >
    <input 
        type="text"
        required={props.required}
        className="hidden-input"
        value={props.value}
        />
    <div 
    onClick={()=>setShow(!show)} className={`baseInput-select ${value == ""?"baseInput-placehoder":""}`}>
        {value?value:props.placeholder?props.placeholder:"Select item"}
        <span className="c-arrow">
            <ArrowDown />
        </span>
    </div>
    </div>
    <div className="p-rel">
    {show && props?.option ?<div className="drop-down p-abs" >
    <ul 
    className="no-deco p-0 m-0"
    >
    {props?.option?.map((a,i)=><li onClick={()=>{
        props.onChange(a)
        setShow(false)
    }} key={i}>{a.title}</li>)}
    </ul>
    </div>:null}
    </div>
    </div>
}
export default BaseSelect;
const ArrowDown = ()=>{
    return <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.5999 7.45834L11.1666 12.8917C10.5249 13.5333 9.4749 13.5333 8.83324 12.8917L3.3999 7.45834" fill="#737373"/>
    <path d="M16.5999 7.45834L11.1666 12.8917C10.5249 13.5333 9.4749 13.5333 8.83324 12.8917L3.3999 7.45834" stroke="#737373" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
}
