export const ToyotaLogo = ()=>{
    return <svg width="118" height="118" viewBox="0 0 118 118" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_5_5135)">
    <g clipPath="url(#clip0_5_5135)">
    <path d="M59.0766 34.7734C39.4103 34.7734 23.4648 45.0886 23.4648 57.8094C23.4648 70.5349 39.4103 80.8548 59.0766 80.8548C78.7428 80.8548 94.6883 70.5396 94.6883 57.8094C94.6883 45.0793 78.7428 34.7734 59.0766 34.7734ZM59.0766 70.9655C56.1514 70.9655 53.7645 65.2042 53.6148 57.9545C55.3745 58.109 57.2045 58.1932 59.0766 58.1932C60.9486 58.1932 62.7786 58.109 64.5384 57.9545C64.3886 65.1995 62.0017 70.9655 59.0766 70.9655ZM53.9751 52.1979C54.7708 47.073 56.7552 43.4365 59.0766 43.4365C61.3979 43.4365 63.3824 47.073 64.178 52.1979C62.5446 52.3429 60.841 52.4225 59.0766 52.4225C57.3121 52.4225 55.6085 52.3429 53.9751 52.1979ZM59.0766 38.1057C55.2575 38.1057 52.0001 43.825 50.7973 51.8094C43.557 50.6815 38.5024 48.1074 38.5024 45.1167C38.5024 41.0824 47.713 37.8109 59.0766 37.8109C70.4401 37.8109 79.6508 41.0824 79.6508 45.1167C79.6508 48.1074 74.5961 50.6815 67.3559 51.8094C66.153 43.825 62.8956 38.1057 59.0766 38.1057ZM28.571 56.9483C28.571 53.045 30.0733 49.3804 32.6895 46.2353C32.6615 46.4552 32.6427 46.6799 32.6427 46.9045C32.6427 51.8234 40.0187 56.0169 50.3105 57.5614C50.3012 57.9171 50.3012 58.2774 50.3012 58.6378C50.3012 67.8064 52.8706 75.5708 56.4229 78.2058C40.8144 77.2651 28.571 68.1059 28.571 56.9483ZM61.7302 78.2058C65.2825 75.5708 67.852 67.8064 67.852 58.6378C67.852 58.2728 67.8473 57.9171 67.8426 57.5614C78.1297 56.0169 85.5104 51.8281 85.5104 46.9045C85.5104 46.6799 85.4917 46.4552 85.4636 46.2353C88.0798 49.3897 89.5822 53.045 89.5822 56.9483C89.5822 68.1059 77.3387 77.2651 61.7302 78.2058Z" fill="#282830"/>
    </g>
    </g>
    <defs>
    <filter id="filter0_d_5_5135" x="0.667155" y="0.753499" width="116.818" height="116.818" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="2.2465"/>
    <feGaussianBlur stdDeviation="2.2465"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5_5135"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5_5135" result="shape"/>
    </filter>
    <clipPath id="clip0_5_5135">
    <rect width="71.888" height="46.7272" fill="white" transform="translate(23.1323 34.4512)"/>
    </clipPath>
    </defs>
    </svg>
    
}