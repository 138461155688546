/* eslint-disable no-useless-escape */
import Axios,{AxiosRequestConfig} from "axios";
import { APIResponse } from "./types";
import { BaseUrlDev } from "./constant";

// import { navigationRef } from "../App";
export function RemoveSpecialCharaters(d: string) {
    d = String(d).trim();
    return d.replace(/[-+,&\/\\#()$~%.;'":*?<>{}@ ]/g, '');
  }
  export function ReturnAllNumbers(d: string) {
    d = String(d).trim();
    return d.replace(/[-+,&\/\\#()$~%.;'":*?<>{}A-Z a-z]/g, '');
  }
  export function ReturnUsername(d: string) {
    d = String(d).trim();
    return d.replace(/[-+,&\/\\#()$~%.;'":*?<>{} 0-9]/g, '');
  }
  export function ReturnAccountUsername(d: string) {
    d = String(d).trim();
    return d.replace(/[-+,&\/\\#()$~%.;'":*?<>{} ]/g, '');
  }
  export function ReturnAllNumbersWithComma(d: string) {
    d = String(d).trim();
    return d.replace(/[-+&\/\\#()$~%.;'":*?<>{}A-Z a-z]/g, '');
  }
  export function ReturnMobile(d: string) {
    d = String(d).trim();
    d = String(d[0]) === '0' ? d.replace('0', '') : d;
    return d.replace(/[-+,&\/\\#()$~%.;'":*?<>{}A-Z a-z]/g, '');
  }
  export function ReturnAllLetter(d: string) {
    d = String(d).trim();
    return d.replace(/[-+,&\/\\#()$~%.;'":*?<>{} 0-9]/g, '');
  }
  export function ReturnComma(str: string) {
    if (str === '' || str === ' ' || `${str[0]}` === "0") {
      return "";
    }
    if (str === '.') {
      return String(str).replace('.', '0');
    }
    
    str = String(str).replace(/[^0-9.]/g, '');
    var getDot = String(str).split('.');
    var firstPart = getDot[0];
    if (firstPart.length >= 4) {
      firstPart = firstPart.replace(/(\d)(?=(\d{3})+$)/g, '$1,');
    }
    if (getDot.length >= 2) {
      return firstPart + '.' + getDot[1];
    }
    if (String(firstPart) === '.00') {
      return '';
    }
    return firstPart;
  }
  export function PostDATA(
    url: string,
    data: any,
    appTYPE: 'json' | 'image' = 'json',
  ) {
    var header = {}
    if(appTYPE === "json")
    {
      header = {
              "Content-Type": "application/json",
              }
    }else{
      header = {
        "Content-Type": "multipart/form-data",
        }
    }
    const formData = new FormData();
    Object.keys(data).forEach((elem,i)=>{
      formData.append(elem,data[elem]);
    })
    const options:AxiosRequestConfig = {
        headers:header,
        method:"POST",
        url:`${BaseUrlDev}${url}`,
        data:appTYPE === "json"?data:formData
    }
    console.log(options)
    return new Promise<APIResponse>((resolve, _reject) => {
        Axios(options).then(({data,statusText})=>{
          console.log(statusText)
            resolve({
                status:true,
                data:data.data,
                message:data.message
            })
        }).catch((error)=>{
            resolve({
                status:false,
                data:{},
                message:error.message
            })
        })
        });
    }

  export function GetDATA(
      url: string,
      data: any
    ) {
     const headers = {
      "content-type":"application/json"
     }
      return new Promise<APIResponse>((resolve, _reject) => {
        const options:AxiosRequestConfig = {
          method:"GET",
          url:`${BaseUrlDev}${url}`,
          headers:headers,
          data
        }
        Axios(options).then(({data})=>{
          resolve({
              status:true,
              data:data.data,
              message:data.data.message
          })
      }).catch((error)=>{
          resolve({
              status:false,
              data:{},
              message:error.message
          })
      })
      });
  }
  export function ReturnMaskAll(s:string){
    var mask:string = String(s).split("").map((a)=>{
    if(a === ".")
      {
        return "."
      }
      return  "X"
    }).join("")
    return mask;
  }