import { useHistory } from "react-router-dom";
import Img1  from "../../../assets/images/sectionThreeImages/1.png"
import Img2  from "../../../assets/images/sectionThreeImages/2.png"
import Img3  from "../../../assets/images/sectionThreeImages/3.png"
import Img4  from "../../../assets/images/sectionThreeImages/4.png"
import { useEffect, useState } from "react";
import { YellowBtn } from "../../../components/yellowBtn";
import { AppRoutes } from "../../../includes/constant";
import { CarProps } from "../../../includes/types";
import Sedan from "../../../assets/images/cars/sedan.png";
import SUV from "../../../assets/images/cars/suv.png";
import { TeslaLogo } from "./partnersLogo/teslaLogo";
import { ChevyLogo } from "./partnersLogo/chevyLogo";
import { AudiLogo } from "./partnersLogo/audiLogo";
import { BMWLOgo } from "./partnersLogo/bmwLogo";
import { CupraLogo } from "./partnersLogo/cupraLogo";
import { ToyotaLogo } from "./partnersLogo/ToyotaLogo";
import { HondaLogo } from "./partnersLogo/HundaLogo";
import { VoxLogo } from "./partnersLogo/VoxLogo";
import { FordLogo } from "./partnersLogo/fordLogo";
import Marquee from "react-fast-marquee";
const SectionFiveComponent = ()=>{
    const history = useHistory();
    useEffect(()=>{
      
    },[])
    return <div className="min-height-100 p-5 text-center anim_section my-5">
    <div className="ff-playfair fs-32">
    Our partners make your dream car ownership possible
    </div>
    <Marquee 
direction="left"
>
    <div className="center gap-5 p-5" >
    <TeslaLogo />
    <ChevyLogo />
    <AudiLogo />
    <BMWLOgo />
    <CupraLogo />
    <ToyotaLogo />
    <VoxLogo />
    <HondaLogo />
    <FordLogo />
    <TeslaLogo />
    <ChevyLogo />
    <AudiLogo />
    <BMWLOgo />
    <CupraLogo />
    <ToyotaLogo />
    <VoxLogo />
    <HondaLogo />
    <FordLogo />
    </div>
    </Marquee>
    </div>
}
export default SectionFiveComponent;

const Arrow = ()=>{
   return <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
   <path d="M5.5 15L15.5 5" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
   <path d="M7.375 5H15.5V13.125" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
   </svg>
   }
