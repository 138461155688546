const Cities = [
{title:"Algoma Country",pcode:"ON"},
{title:"Barrie",pcode:"ON"},
{title:"Bay of Quinte and Belleville ",pcode:"ON"},
{title:"Bayfield",pcode:"ON"},
{title:"Blind River",pcode:"ON"},
{title:"Bracebridge ",pcode:"ON"},
{title:"Brampton ",pcode:"ON"},
{title:"Brantford ",pcode:"ON"},
{title:"Brockville",pcode:"ON"},
{title:"Burlington",pcode:"ON"},
{title:"Caledon",pcode:"ON"},
{title:"Cambridge",pcode:"ON"},
{title:"Chatham-Kent",pcode:"ON"},
{title:"Clarington",pcode:"ON"},
{title:"Collingwood",pcode:"ON"},
{title:"Cornwall and the Counties",pcode:"ON"},
{title:"County of Brant",pcode:"ON"},
{title:"Dufferin County",pcode:"ON"},
{title:"Elgin County",pcode:"ON"},
{title:"Elliot Lake",pcode:"ON"},
{title:"Elora and Fergus",pcode:"ON"},
{title:"Frontenac County North",pcode:"ON"},
{title:"Frontenac County South",pcode:"ON"},
{title:"Gananoque and the 1000 Islands",pcode:"ON"},
{title:"Georgina",pcode:"ON"},
{title:"Goderich",pcode:"ON"},
{title:"Grand Bend ",pcode:"ON"},
{title:"Gravenhurst",pcode:"ON"},
{title:"Guelph ",pcode:"ON"},
{title:"Haldimand County",pcode:"ON"},
{title:"Haliburton Highlands",pcode:"ON"},
{title:"Halton Hills",pcode:"ON"},
{title:"Hamilton",pcode:"ON"},
{title:"Hastings County ",pcode:"ON"},
{title:"Huntsville",pcode:"ON"},
{title:"Kawartha Lakes  ",pcode:"ON"},
{title:"Kenora",pcode:"ON"},
{title:"Killarney",pcode:"ON"},
{title:"Kincardine ",pcode:"ON"},
{title:"Kingston ",pcode:"ON"},
{title:"Kingsville ",pcode:"ON"},
{title:"Kitchener-Waterloo",pcode:"ON"},
{title:"Lanark County",pcode:"ON"},
{title:"Leamington",pcode:"ON"},
{title:"Lennox and Addington North",pcode:"ON"},
{title:"Lennox and Addington South",pcode:"ON"},
{title:"London",pcode:"ON"},
{title:"Manitoulin Island",pcode:"ON"},
{title:"Markham and Unionville",pcode:"ON"},
{title:"Middlesex County ",pcode:"ON"},
{title:"Midland and Penetanguishene",pcode:"ON"},
{title:"Milton",pcode:"ON"},
{title:"Mississauga",pcode:"ON"},
{title:"Muskoka",pcode:"ON"},
{title:"Niagara Benchlands",pcode:"ON"},
{title:"Niagara Falls",pcode:"ON"},
{title:"Niagara-on-the-Lake ",pcode:"ON"},
{title:"Niagara’s South Coast",pcode:"ON"},
{title:"Norfolk County ",pcode:"ON"},
{title:"North Bay",pcode:"ON"},
{title:"Northumberland County",pcode:"ON"},
{title:"Oakville",pcode:"ON"},
{title:"Orillia and Lake Country ",pcode:"ON"},
{title:"Ottawa ",pcode:"ON"},
{title:"Ottawa Valley ",pcode:"ON"},
{title:"Owen Sound",pcode:"ON"},
{title:"Oxford County ",pcode:"ON"},
{title:"Parry Sound ",pcode:"ON"},
{title:"Peterborough and the Kawarthas",pcode:"ON"},
{title:"Pickering and Ajax",pcode:"ON"},
{title:"Port Perry",pcode:"ON"},
{title:"Prescott and Russell",pcode:"ON"},
{title:"Prince Edward County ",pcode:"ON"},
{title:"Richmond Hill",pcode:"ON"},
{title:"Sarnia-Lambton",pcode:"ON"},
{title:"Sauble Beach",pcode:"ON"},
{title:"Saugeen Shores",pcode:"ON"},
{title:"Sault Ste. Marie",pcode:"ON"},
{title:"Six Nations of the Grand River",pcode:"ON"},
{title:"St. Catharines",pcode:"ON"},
{title:"St. Jacobs and Elmira",pcode:"ON"},
{title:"St. Marys",pcode:"ON"},
{title:"Stratford",pcode:"ON"},
{title:"Sudbury",pcode:"ON"},
{title:"Sunset Country",pcode:"ON"},
{title:"Superior Country",pcode:"ON"},
{title:"The Blue Mountains",pcode:"ON"},
{title:"Thornbury and Clarksburg",pcode:"ON"},
{title:"Thousand Islands and Rideau Canal",pcode:"ON"},
{title:"Thunder Bay  ",pcode:"ON"},
{title:"Timmins",pcode:"ON"},
{title:"Tobermory",pcode:"ON"},
{title:"Toronto ",pcode:"ON"},
{title:"Uxbridge",pcode:"ON"},
{title:"Vaughan",pcode:"ON"},
{title:"Wasaga Beach",pcode:"ON"},
{title:"Wawa",pcode:"ON"},
{title:"Whitby and Oshawa",pcode:"ON"},
{title:"Wiarton",pcode:"ON"},
{title:"Windsor",pcode:"ON"},
{title:"York",pcode:"ON"},
{title:"Barkerville",pcode:"BC"},
{title:"Burnaby",pcode:"BC"},
{title:"Campbell River",pcode:"BC"},
{title:"Chilliwack",pcode:"BC"},
{title:"Courtenay",pcode:"BC"},
{title:"Cranbrook",pcode:"BC"},
{title:"Dawson Creek",pcode:"BC"},
{title:"Delta",pcode:"BC"},
{title:"Esquimalt",pcode:"BC"},
{title:"Fort Saint James",pcode:"BC"},
{title:"Fort Saint John",pcode:"BC"},
{title:"Hope",pcode:"BC"},
{title:"Kamloops",pcode:"BC"},
{title:"Kelowna",pcode:"BC"},
{title:"Kimberley",pcode:"BC"},
{title:"Kitimat",pcode:"BC"},
{title:"Langley",pcode:"BC"},
{title:"Nanaimo",pcode:"BC"},
{title:"Nelson",pcode:"BC"},
{title:"New Westminster",pcode:"BC"},
{title:"North Vancouver",pcode:"BC"},
{title:"Oak Bay",pcode:"BC"},
{title:"Penticton",pcode:"BC"},
{title:"Powell River",pcode:"BC"},
{title:"Prince George",pcode:"BC"},
{title:"Prince Rupert",pcode:"BC"},
{title:"Quesnel",pcode:"BC"},
{title:"Revelstoke",pcode:"BC"},
{title:"Rossland",pcode:"BC"},
{title:"Trail",pcode:"BC"},
{title:"Vancouver",pcode:"BC"},
{title:"Vernon",pcode:"BC"},
{title:"Victoria",pcode:"BC"},
{title:"West Vancouver",pcode:"BC"},
{title:"White Rock.",pcode:"BC"}
]

export default Cities;