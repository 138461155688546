 export const ChevyLogo = ()=>{
    return <svg width="73" height="37" viewBox="0 0 73 37" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_5_4858)">
    <path d="M8.02733 7.95218C8.3483 7.23065 8.62399 6.64463 10.6437 6.64463H22.3028C22.9457 6.64463 23.5879 6.32892 23.5879 5.42789V2.22841C23.5879 1.86566 23.9555 1.36927 24.9192 1.36927H49.064C49.7522 1.36927 50.3038 1.73038 50.3038 2.58695V5.11218C50.3038 5.78807 50.6248 6.50936 51.6346 6.50936H71.0053C71.6935 6.50936 72.6116 6.6891 72.1989 7.5466C71.7852 8.40223 65.4972 20.9319 65.0707 21.7691C64.907 22.0899 64.7795 22.5178 63.5063 22.5178H51.5989C51.0344 22.5178 50.107 22.5178 50.107 23.5899V26.5719C50.107 27.0007 49.7803 27.644 48.7436 27.644H24.8541C24.1083 27.644 23.5631 27.2677 23.5631 26.5361V23.9107C23.5631 23.3392 23.2536 22.5007 22.3812 22.5007H2.32786C1.69093 22.5007 1.09118 22.0181 1.47314 21.0904C1.85511 20.161 8.02733 7.95218 8.02733 7.95218Z" fill="#C7D6EE"/>
    <path d="M3.00684 19.6976L3.56418 19.6992L9.04324 8.99216L8.48566 8.99053L3.00684 19.6976Z" fill="url(#paint0_linear_5_4858)"/>
    <path d="M22.4287 21.2088H3.67355C3.01922 21.2088 2.78976 20.9153 3.04448 20.4157C4.46464 17.6414 9.13473 8.51808 9.25578 8.27586C9.3978 7.99104 9.74164 7.6428 10.3767 7.6428H22.9622C24.2678 7.6428 24.4903 7.08228 24.4903 6.06985V3.05456C24.4903 2.64898 24.7641 2.37937 25.2459 2.37937H48.2971C48.9162 2.37937 49.1904 2.5804 49.1904 2.98599V5.21352C49.1904 6.09138 49.0215 7.50823 51.0135 7.50823H70.0611C70.9165 7.50823 71.1386 8.07482 70.6859 8.97164C69.0003 12.3113 64.8525 20.2299 64.7236 20.4985C64.5601 20.84 64.0528 21.1405 63.2424 21.1405H50.7395C49.708 21.1405 48.6779 21.8154 48.6779 22.8286V25.4018C48.6779 25.8705 48.3912 26.2026 47.8816 26.2026H25.5147C24.8228 26.2026 24.5587 25.8177 24.5587 25.3042V22.9631C24.5587 22.1536 24.009 21.2088 22.4287 21.2088Z" fill="#E9CF98"/>
    <path d="M47.4409 20.6042L50.068 23.2935C50.068 23.2935 50.2555 22.7039 50.9677 22.5577L48.0259 19.8623C48.0259 19.8623 47.5534 20.1249 47.4409 20.6042Z" fill="white"/>
    <path d="M22.4902 22.5202C22.4902 22.5202 23.5411 22.5579 23.5411 23.9209L25.9796 20.3828L25.3043 19.9035L22.4902 22.5202Z" fill="white"/>
    <path d="M48.4091 9.12354L51.1791 6.47169C51.1791 6.47169 50.8036 6.42653 50.4273 5.8267L47.6807 8.57075L48.4091 9.12354Z" fill="white"/>
    <path d="M47.2114 3.7047L49.7706 1.56118C49.7706 1.56118 50.0525 1.72196 50.1697 1.97612L47.4933 4.39697L47.2114 3.7047Z" fill="white"/>
    <path d="M23.6069 2.33693C23.6069 1.89297 24.0268 1.3692 24.7142 1.3692L26.6558 3.72662C26.6558 3.72662 26.0746 3.72662 25.9833 3.93702C25.8909 4.14765 25.7994 4.59863 25.7994 4.59863L23.6069 2.33693Z" fill="white"/>
    <path d="M22.7676 6.58313C22.7676 6.58313 23.5973 6.45394 23.5973 5.58287L25.5219 7.80689L24.2952 8.67773L22.7676 6.58313Z" fill="white"/>
    <path d="M36.9986 1.36979L36.9102 2.38386H48.0005C48.3644 2.38386 48.5691 2.47373 48.7063 2.7417L49.9344 1.66935C49.9344 1.66935 49.6258 1.36979 49.0637 1.36979H36.9986Z" fill="url(#paint1_linear_5_4858)"/>
    <path d="M24.7133 1.36937C24.4176 1.36937 24.0761 1.51307 23.895 1.73563L24.6909 2.58447C24.6909 2.58447 24.8498 2.38344 25.4867 2.38344H36.9107L36.9991 1.36937H24.7133Z" fill="url(#paint2_linear_5_4858)"/>
    <path d="M23.6057 2.33707V5.42772C23.6057 5.91287 23.5068 6.03177 23.5068 6.03177L24.3849 6.94824C24.3849 6.94824 24.5105 6.63183 24.5105 6.43735V3.37501C24.5105 3.1817 24.5281 2.89992 24.5281 2.89992L23.7037 1.89708C23.7037 1.89708 23.6057 2.07238 23.6057 2.33707Z" fill="url(#paint3_linear_5_4858)"/>
    <path d="M49.2089 2.80631C49.2089 2.80631 49.1851 3.16579 49.1851 3.3591C49.1851 3.55241 49.2089 6.16306 49.2089 6.35684C49.2089 6.55015 49.4955 6.90869 49.4955 6.90869L50.5464 5.99549C50.5464 5.99549 50.2971 5.91334 50.2971 5.42819V2.33753C50.2971 2.07284 50.0767 1.89755 50.0767 1.89755L49.2089 2.80631Z" fill="url(#paint4_linear_5_4858)"/>
    <path d="M22.3691 6.64856C22.0398 6.64856 10.7259 6.66143 10.1231 6.66143C9.24833 6.66143 8.73389 7.04033 8.73389 7.04033L9.4535 7.99121C9.4535 7.99121 9.74134 7.64273 10.3766 7.64273H23.143C23.5877 7.64273 23.9218 7.44521 23.9218 7.44521L23.0875 6.4611C23.0875 6.4611 22.6779 6.64856 22.3691 6.64856Z" fill="url(#paint5_linear_5_4858)"/>
    <path d="M2.66843 20.4981L1.36384 21.3966C1.36384 21.3966 1.26042 21.768 1.45533 22.0615C1.65073 22.354 1.94906 22.3994 1.94906 22.3994L3.64705 21.2082C3.64705 21.2082 2.68606 21.1637 3.0859 20.4647C3.48574 19.7656 3.46405 19.5021 3.46405 19.5021C3.46405 19.5021 2.9975 19.5774 2.66843 20.4981Z" fill="url(#paint6_linear_5_4858)"/>
    <path d="M6.25008 11.6996C5.20974 13.8026 1.71557 20.5585 1.59333 20.7998C1.47086 21.0401 1.34814 21.4604 1.34814 21.4604L2.69468 20.4986L9.00415 8.1528C9.00415 8.1528 7.29066 9.59539 6.25008 11.6996Z" fill="url(#paint7_linear_5_4858)"/>
    <path d="M3.67303 21.2381L1.94238 22.4609C1.94238 22.4609 21.6555 22.5087 22.1535 22.5087C22.6508 22.5087 22.8597 22.6021 22.8597 22.6021L23.7476 21.6111C23.7476 21.6111 22.8995 21.2381 22.4285 21.2381H3.67303Z" fill="url(#paint8_linear_5_4858)"/>
    <path d="M23.3657 23.1435C23.3657 23.1435 23.5473 23.5018 23.5473 23.7878V26.3479C23.5473 26.7141 23.6926 27.0317 23.6926 27.0317L24.5588 26.0296V22.9628C24.5588 22.4735 24.2841 22.0618 24.2841 22.0618L23.3657 23.1435Z" fill="url(#paint9_linear_5_4858)"/>
    <path d="M48.6748 22.647V25.7138L50.034 26.8818C50.034 26.8818 50.0678 26.5703 50.0678 26.205V23.6437C50.0678 23.3582 50.1519 23.1785 50.1519 23.1785L48.9636 22.0114C48.9636 22.0114 48.6748 22.1567 48.6748 22.647Z" fill="url(#paint10_linear_5_4858)"/>
    <path d="M47.6582 26.2248H36.9717V27.6494H48.7969C49.1989 27.6494 49.6676 27.3868 49.7567 27.3201L48.4166 26.0718C48.4166 26.0718 48.3282 26.2248 47.6582 26.2248Z" fill="url(#paint11_linear_5_4858)"/>
    <path d="M24.1006 27.4719C24.1006 27.4719 24.3758 27.6636 25.145 27.6636C25.9134 27.6636 36.9723 27.6498 36.9723 27.6498V26.2252H25.5582C25.3516 26.2252 25.2134 26.1875 25.2134 26.1875L24.1006 27.4719Z" fill="url(#paint12_linear_5_4858)"/>
    <path d="M27.1695 3.69818C26.5505 3.69818 25.9324 4.03612 25.9324 4.71131V8.01868C25.9324 8.69293 25.5197 8.96324 25.0388 8.96324H11.3543C10.7984 8.96324 10.3683 9.19095 10.1212 9.65036C9.91887 10.0279 6.50143 16.7552 5.39699 18.9293C5.15752 19.4009 5.36673 19.6876 5.98126 19.6876H25.1082C25.9324 19.6876 26.0701 20.4304 26.0701 21.1723V23.7425C26.0701 24.2159 26.5507 24.7559 27.1007 24.7559H36.7228V3.69818H27.1695Z" fill="url(#paint13_linear_5_4858)"/>
    <path d="M49.5762 21.5195L50.678 22.6318C50.678 22.6318 50.953 22.4823 52.2387 22.4823H63.9896C64.2339 22.4823 64.5705 22.3618 64.5705 22.3618L58.359 21.1296C58.359 21.1296 51.1674 21.1345 50.831 21.1296C50.8183 21.1293 50.8057 21.1291 50.7931 21.1291C50.0639 21.1291 49.5762 21.5195 49.5762 21.5195Z" fill="url(#paint14_linear_5_4858)"/>
    <path d="M49.9902 7.31091C49.9902 7.31091 50.5273 7.48713 50.966 7.48713H69.4679C70.031 7.48713 70.3595 7.52832 70.3595 7.52832L71.8548 6.65304C71.8548 6.65304 71.3749 6.51846 70.8952 6.51846H51.6801C51.2 6.51846 50.9998 6.40823 50.9998 6.40823L49.9902 7.31091Z" fill="url(#paint15_linear_5_4858)"/>
    <path d="M70.904 8.00251C70.904 8.00251 70.9886 8.46356 70.8287 8.77693C70.6693 9.09007 65.0771 19.8924 64.8247 20.3885C64.5714 20.8842 64.001 21.0672 64.001 21.0672L64.6381 22.3188C64.6381 22.3188 64.864 22.1503 64.9972 21.9802C65.13 21.8108 72.0108 8.05892 72.2236 7.57657C72.4357 7.09376 72.2494 6.91917 72.2494 6.91917L70.904 8.00251Z" fill="url(#paint16_linear_5_4858)"/>
    <path d="M63.4087 21.1603C62.6743 21.1603 58.543 21.1912 58.543 21.1912L64.4183 22.3628C64.4183 22.3628 64.7857 22.2137 64.9694 21.9118C65.1534 21.6113 64.969 21.5214 64.755 21.7014C64.5412 21.8828 64.1741 21.5823 64.3571 21.3717C64.5412 21.1603 64.7242 20.4989 64.7242 20.4989C64.7242 20.4989 64.1435 21.1603 63.4087 21.1603Z" fill="url(#paint17_linear_5_4858)"/>
    <path d="M8.74994 20.2297C4.90216 20.482 3.67334 21.1965 3.67334 21.1965H22.0478C24.1373 21.1965 23.8987 21.4771 23.8871 21.4898L25.383 19.936C25.383 19.936 21.7487 19.936 19.4398 19.8944C19.1462 19.889 18.8169 19.8864 18.4573 19.8864C15.9894 19.8864 12.1088 20.01 8.74994 20.2297ZM23.8866 21.4902C23.8866 21.4902 23.8868 21.49 23.8871 21.4898L23.8866 21.4902Z" fill="url(#paint18_linear_5_4858)"/>
    <path d="M52.0896 19.8948C50.4548 19.9364 47.8809 19.9364 47.8809 19.9364L49.5963 21.5229C49.5963 21.5229 49.7728 21.1518 51.2864 21.1518C52.8009 21.1518 63.7147 21.1148 63.7147 21.1148C63.7147 21.1148 63.8987 20.5288 60.6852 20.3036C58.1537 20.126 54.3335 19.8903 52.4868 19.8903C52.3417 19.8903 52.209 19.8917 52.0896 19.8948Z" fill="url(#paint19_linear_5_4858)"/>
    <path d="M48.2012 9.12916C48.2012 9.12916 50.7756 9.12916 52.4109 9.17105C54.0458 9.21294 63.9566 8.77155 66.6828 8.51926C69.4083 8.26744 70.278 7.55317 70.278 7.55317C70.278 7.55317 52.9492 7.64398 51.4351 7.64398C49.922 7.64398 50.1332 7.34933 50.1332 7.34933L48.2012 9.12916Z" fill="url(#paint20_linear_5_4858)"/>
    <path d="M24.376 22.1069C24.376 22.1069 24.5597 22.6922 24.5597 23.1432V25.172C24.5597 25.7126 24.7436 25.9839 24.7436 25.9839L26.3042 24.4505C26.3042 24.4505 26.0711 23.9099 26.0711 23.5041V21.2168C26.0711 20.6879 25.9367 20.2578 25.9367 20.2578L24.376 22.1069Z" fill="url(#paint21_linear_5_4858)"/>
    <path d="M47.2751 21.204C47.2751 21.7329 47.2386 23.1481 47.2386 23.5546C47.2386 23.9593 47.1436 24.4505 47.1436 24.4505L48.7041 25.9839C48.7041 25.9839 48.8883 25.7126 48.8883 25.172V23.1432C48.8883 22.6922 49.072 22.1069 49.072 22.1069L47.4007 20.2149C47.4007 20.2149 47.2751 20.6762 47.2751 21.204Z" fill="url(#paint22_linear_5_4858)"/>
    <path d="M25.248 26.164L36.6315 26.2539L36.6775 26.253V24.7556L26.7626 24.7205L25.248 26.164Z" fill="url(#paint23_linear_5_4858)"/>
    <path d="M36.7235 24.7556H36.6777V26.2529L48.3826 26.1195L46.5009 24.7205L36.7235 24.7556Z" fill="url(#paint24_linear_5_4858)"/>
    <path d="M25.4015 2.37475C25.0801 2.37475 24.7827 2.54489 24.7827 2.54489L26.2915 4.00293C26.2915 4.00293 26.4402 3.68628 27.5034 3.68628H36.723V2.37475H25.4015Z" fill="url(#paint25_linear_5_4858)"/>
    <path d="M36.7227 2.37558V3.68711H45.942C47.0052 3.68711 47.2254 4.1416 47.2254 4.1416L48.7337 2.68427C48.7337 2.68427 48.3658 2.37558 48.0444 2.37558H36.7227Z" fill="url(#paint26_linear_5_4858)"/>
    <path d="M24.4948 3.64943V6.29705C24.4948 6.61112 24.3545 6.98324 24.3545 6.98324L25.8321 8.63037C25.8321 8.63037 25.9319 8.49346 25.9319 7.80751C25.9319 7.12109 25.9915 4.43508 25.9915 4.43508L24.5144 2.80761C24.5144 2.80761 24.4948 3.23799 24.4948 3.64943Z" fill="url(#paint27_linear_5_4858)"/>
    <path d="M47.2876 4.46667V7.7586C47.2876 8.44479 47.6145 8.63037 47.6145 8.63037L49.4202 6.84376C49.4202 6.84376 49.1848 6.47 49.1848 6.15686C49.1848 5.84279 49.1695 3.92863 49.1695 3.51696C49.1695 3.10529 49.1879 2.72452 49.1879 2.72452L47.2876 4.46667Z" fill="url(#paint28_linear_5_4858)"/>
    <path d="M23.2738 7.67926H10.6053C10.2381 7.67926 9.641 7.81453 9.45752 7.9952L10.6053 9.16748C10.6053 9.16748 10.9263 8.94234 11.7069 8.94234H24.8803C25.248 8.94234 25.6147 8.80637 25.6147 8.80637L24.1464 7.31908C24.1464 7.31908 23.7788 7.67926 23.2738 7.67926Z" fill="url(#paint29_linear_5_4858)"/>
    <path d="M62.7314 19.7363C62.7314 19.7363 63.4408 19.4744 63.6626 20.3019C63.6626 20.3019 63.8397 20.5638 64.3275 19.8662C64.815 19.1699 69.4265 9.85302 69.3809 9.72126C69.3364 9.59114 68.4054 9.41655 68.4054 9.41655L62.7314 19.7363Z" fill="url(#paint30_linear_5_4858)"/>
    <path d="M68.5391 9.50579C68.7614 9.63591 68.9828 9.8105 68.6726 10.4632C68.3626 11.1152 64.8157 17.9085 64.0625 19.2142C63.3088 20.5201 63.9729 21.043 63.9729 21.043C63.9729 21.043 64.5501 20.7811 64.7233 20.4988C64.8979 20.2157 70.3131 9.67944 70.6858 8.97196C71.0587 8.26424 70.8 7.98106 70.8 7.98106L68.5391 9.50579Z" fill="url(#paint31_linear_5_4858)"/>
    <path d="M36.7227 3.69818V24.7559H46.2029C46.7522 24.7559 47.2335 24.5532 47.2335 23.9447V20.8042C47.2335 20.1969 47.6453 19.7253 48.1268 19.7253H62.4283C62.9304 19.7253 63.3809 19.437 63.6426 18.9621C64.8111 16.8399 68.0296 10.6855 68.2731 10.1893C68.5703 9.58367 68.3863 9.23285 67.6974 9.23285H48.7452C47.921 9.23285 47.3014 8.626 47.3014 7.88341V4.441C47.3014 3.96848 47.0269 3.69818 46.4086 3.69818H36.7227Z" fill="url(#paint32_linear_5_4858)"/>
    <path d="M3.51855 20.6108L5.35333 19.5181C5.35333 19.5181 5.14316 19.4283 5.47342 18.7788C5.80416 18.1294 8.90206 11.9256 8.90206 11.9256L8.21081 11.7786L3.51855 20.6108Z" fill="url(#paint33_linear_5_4858)"/>
    <path d="M3.35236 19.8067C2.89319 20.6186 2.98565 20.9336 2.98565 20.9336L4.08699 20.2123C4.08699 20.2123 8.99822 10.8802 9.50314 9.79778C10.0083 8.71608 9.25557 8.27539 9.25557 8.27539C9.25557 8.27539 3.81153 18.9956 3.35236 19.8067Z" fill="url(#paint34_linear_5_4858)"/>
    <path d="M4.09505 21.2355C4.02214 21.2835 3.3509 21.3647 3.21984 21.2355C3.08831 21.1073 2.86885 21.0071 3.04423 20.8634C3.21984 20.7195 5.37868 19.4592 5.37868 19.4592C5.37868 19.4592 5.11657 19.6022 7.33474 19.6022H25.0791C25.5462 19.6022 25.9837 19.917 26.0423 20.118C26.1009 20.3191 25.9839 20.4047 25.8381 20.3764C25.6927 20.3474 25.3127 20.2327 25.2252 20.089C25.1378 19.946 25.342 19.8313 24.0576 19.8313C22.7735 19.8313 12.9675 19.975 10.7498 20.1754C8.53163 20.3764 5.6122 20.2327 4.09505 21.2355Z" fill="white"/>
    <path d="M69.637 7.5914C69.7097 7.54436 70.3817 7.46315 70.5127 7.5914C70.6438 7.72058 70.8632 7.82075 70.6878 7.96445C70.5127 8.10744 68.3532 9.36865 68.3532 9.36865C68.3532 9.36865 68.615 9.22566 66.3973 9.22566H48.6525C48.1854 9.22566 47.7479 8.91088 47.6898 8.70985C47.6312 8.50858 47.7477 8.42222 47.8931 8.45147C48.0398 8.48049 48.4189 8.59517 48.5069 8.73793C48.5943 8.88186 48.3896 8.9956 49.6742 8.9956C50.9588 8.9956 60.7636 8.85284 62.9827 8.65251C65.2002 8.45147 68.1199 8.59517 69.637 7.5914Z" fill="white"/>
    <path d="M9.8242 9.24914C9.8242 9.04624 9.93882 8.66312 9.84732 8.55102C9.75534 8.43798 10.0308 8.30271 10.2145 8.41481C10.398 8.52785 10.7881 8.61772 10.8338 8.70829C10.8798 8.79816 10.8109 9.0233 10.5586 9.11387C10.306 9.20374 10.0765 9.40665 9.68648 10.24C9.29617 11.0751 7.06966 15.3125 7.06966 15.3125C7.06966 15.3125 7.39134 14.6366 7.25362 14.6134C7.11589 14.5912 6.79492 15.155 6.79492 15.155L9.8242 9.24914Z" fill="white"/>
    <path d="M25.1709 8.85035C24.981 8.86908 24.9278 8.66476 25.0899 8.63808C25.2528 8.6114 25.4685 8.59877 25.6574 7.86788C25.8474 7.13722 25.7122 4.92022 25.6848 4.52213C25.6574 4.12357 25.4687 3.6459 25.7394 3.43363C26.0094 3.22066 26.1988 3.67258 26.902 3.67258C27.6047 3.67258 29.2002 3.61946 29.2002 3.61946C29.2002 3.61946 26.9561 3.7779 26.577 3.83173C26.1986 3.88485 25.874 4.17646 25.9279 4.70795C25.9825 5.23921 25.8474 7.97483 25.7394 8.26667C25.631 8.55851 25.4413 8.82367 25.1709 8.85035Z" fill="white"/>
    <path d="M40.312 3.63533C40.0144 3.6489 46.0131 3.7046 46.3913 3.7046C46.7699 3.7046 47.2024 3.81741 47.2839 4.18882C47.3649 4.56117 47.3075 5.77979 47.3075 5.77979C47.3075 5.77979 47.4938 4.2155 47.7643 3.92366C48.0345 3.63088 47.9535 3.20658 47.602 3.28522C47.2503 3.36572 44.4664 3.45138 40.312 3.63533Z" fill="white"/>
    <path d="M48.3214 19.9159C48.1326 19.8885 47.9168 19.8628 47.8622 20.0219C47.8081 20.1801 48.1048 20.2864 47.8081 20.4446C47.5122 20.6037 47.2915 20.7013 47.2915 20.5688C47.2915 20.4361 47.4445 19.7558 48.0111 19.7558C48.5775 19.7558 62.5601 19.7294 62.7772 19.7294C62.9922 19.7294 63.2624 19.7558 63.3698 19.9681C63.4775 20.1801 63.6122 20.71 63.6937 20.8691C63.7742 21.0282 63.6396 21.0282 63.3965 20.71C63.1539 20.3917 62.5339 20.2599 62.0488 20.2864C61.5629 20.3128 48.3214 19.9159 48.3214 19.9159Z" fill="white"/>
    <path d="M63.7485 20.3657C63.7485 20.3657 63.6138 20.0748 63.5328 19.8883C63.4518 19.7034 63.2095 19.6248 63.2095 19.6248C63.2095 19.6248 63.4787 19.3055 63.8562 18.6175C64.2341 17.9297 66.0141 14.6438 66.2028 14.1935C66.2028 14.1935 65.7979 15.4131 66.5805 14.4058C66.5805 14.4058 64.0451 19.12 63.8831 19.4382C63.7218 19.7565 63.7485 20.3657 63.7485 20.3657Z" fill="white"/>
    <path d="M26.2382 24.0736C26.2382 24.0736 26.2703 24.4544 26.0452 24.6126C25.8195 24.771 25.7228 25.0559 25.9162 25.1827C26.1097 25.3093 26.2386 25.2459 26.3676 25.0559C26.4965 24.8661 26.9482 24.7076 27.2382 24.771C27.2382 24.771 26.2382 24.8967 26.2382 24.0736Z" fill="white"/>
    <path d="M40.1372 24.7524C40.1372 24.7524 45.5796 25.0339 45.902 25.0484C46.2234 25.0629 46.45 25.0732 46.45 25.0732C46.45 25.0732 45.8724 24.9834 46.0433 24.8011L40.1372 24.7524Z" fill="white"/>
    <path d="M46.5883 24.7078C46.5883 24.7078 46.4272 24.961 46.685 25.0869C46.9433 25.2136 47.2016 25.3402 47.3627 25.1501C47.5235 24.9603 47.2338 24.4537 47.2657 24.1054C47.2657 24.1054 47.3946 24.6444 46.5883 24.7078Z" fill="white"/>
    <path d="M53.2261 25.1664H52.9247V25.6679H53.2261C53.3796 25.6679 53.4251 25.608 53.4251 25.5095V25.3239C53.4251 25.1973 53.365 25.1664 53.2261 25.1664ZM53.2261 25.8303H52.9247V26.2429H52.7412V25.0012H53.2261C53.5006 25.0012 53.6086 25.121 53.6086 25.3239V25.5095C53.6086 25.644 53.5611 25.7346 53.4251 25.7945L53.7653 26.2429H53.548L53.2261 25.8303ZM53.1696 26.6211C53.8168 26.6211 54.1566 26.1854 54.1566 25.6506C54.1566 25.1158 53.8168 24.6735 53.1696 24.6735C52.5218 24.6735 52.1741 25.1158 52.1741 25.6506C52.1741 26.1854 52.5218 26.6211 53.1696 26.6211ZM53.1696 24.5092C53.8869 24.5092 54.3248 25.0185 54.3248 25.6483C54.3248 26.2694 53.8869 26.7861 53.1696 26.7861C52.4481 26.7861 52.0063 26.2694 52.0063 25.6483C52.0063 25.0185 52.4481 24.5092 53.1696 24.5092Z" fill="black"/>
    <path d="M16.2778 32.6957H14.2753V30.1925H12.3428V36.4155H14.2753V33.7323H16.2778V36.4155H18.2123V30.1925H16.2778V32.6957Z" fill="black"/>
    <path d="M51.83 35.3799V30.1925H49.897V36.4155H54.5965V35.3799H51.83Z" fill="black"/>
    <path d="M62.8198 30.1925V31.2303H64.579V36.4155H66.5112V31.2303H68.2718V30.1925H62.8198Z" fill="black"/>
    <path d="M5.36914 31.4882C5.36914 30.7105 5.84748 30.1925 6.56499 30.1925H10.4415V31.2294H7.66236C7.44626 31.2294 7.30417 31.3866 7.30417 31.6188V34.9892C7.30417 35.2233 7.44626 35.3799 7.66236 35.3799H10.4415V36.4155H6.56499C5.84748 36.4155 5.36914 35.8963 5.36914 35.1195V31.4882Z" fill="black"/>
    <path d="M38.9933 33.891L40.6644 36.4155H38.6095L37.13 33.8485V33.0372H37.9286C38.1438 33.0372 38.287 32.8797 38.287 32.6478V31.6188C38.287 31.3866 38.1438 31.2303 37.9286 31.2303H36.5763V36.4155H34.6421V30.1925H39.0251C39.7449 30.1925 40.2207 30.7105 40.2207 31.4882L40.2226 32.7461C40.2226 33.4313 39.8014 33.891 39.1666 33.891H38.9933Z" fill="black"/>
    <path d="M45.6944 35.3799C45.9083 35.3799 46.0524 35.2236 46.0524 34.9904V31.6188C46.0524 31.3869 45.9083 31.2294 45.6944 31.2294H44.3461C44.1311 31.2294 43.9868 31.3869 43.9868 31.6188V34.9904C43.9868 35.2236 44.1311 35.3799 44.3461 35.3799H45.6944ZM42.0537 31.4882C42.0537 30.7105 42.5304 30.1925 43.2484 30.1925H46.7907C47.5076 30.1925 47.9871 30.7105 47.9871 31.4882V35.1198C47.9871 35.8966 47.5076 36.4155 46.7907 36.4155H43.2484C42.5304 36.4155 42.0537 35.8966 42.0537 35.1198V31.4882Z" fill="black"/>
    <path d="M26.8145 30.1925H28.8879L30.1251 35.0035C30.1344 35.0472 30.1662 35.0728 30.211 35.0728C30.2548 35.0728 30.2892 35.0472 30.2973 35.0035L31.5002 30.1925H33.1229L31.624 35.5328C31.4425 36.1408 31.1147 36.4155 30.4265 36.4155H29.4616C29.019 36.4155 28.658 36.1912 28.4411 35.5282L26.8145 30.1925Z" fill="black"/>
    <path d="M22.0996 35.1833C22.0996 35.3027 22.1711 35.3787 22.2797 35.3787H25.457V36.4155H21.361C20.6438 36.4155 20.166 35.8963 20.166 35.1207V31.4882C20.166 30.7105 20.6438 30.1925 21.361 30.1925H25.457V31.2303H22.2797C22.1711 31.2303 22.0996 31.3075 22.0996 31.4247V32.6948H25.0924V33.7326H22.0996V35.1833Z" fill="black"/>
    <path d="M57.8772 35.1833C57.8772 35.3027 57.9481 35.3787 58.0548 35.3787H61.2338V36.4155H57.1375C56.4205 36.4155 55.9399 35.8963 55.9399 35.1207V31.4882C55.9399 30.7105 56.4205 30.1925 57.1375 30.1925H61.2338V31.2303H58.0548C57.9481 31.2303 57.8772 31.3075 57.8772 31.4247V32.6948H60.868V33.7326H57.8772V35.1833Z" fill="black"/>
    </g>
    <defs>
    <linearGradient id="paint0_linear_5_4858" x1="8.67105" y1="9.17364" x2="8.64297" y2="19.7567" gradientUnits="userSpaceOnUse">
    <stop stopColor="#C7D6EE"/>
    <stop offset="0.185394" stopColor="#91AFC8"/>
    <stop offset="1" stopColor="#002334"/>
    </linearGradient>
    <linearGradient id="paint1_linear_5_4858" x1="39.876" y1="-1.45641" x2="46.3774" y2="5.16294" gradientUnits="userSpaceOnUse">
    <stop stopColor="#E1E8F5"/>
    <stop offset="0.89888" stopColor="#92A5C1"/>
    <stop offset="0.955063" stopColor="#476886"/>
    <stop offset="1" stopColor="#E1E8F5"/>
    </linearGradient>
    <linearGradient id="paint2_linear_5_4858" x1="27.278" y1="5.12548" x2="33.9288" y2="-1.64609" gradientUnits="userSpaceOnUse">
    <stop stopColor="#E1E8F5"/>
    <stop offset="0.0393219" stopColor="#476886"/>
    <stop offset="0.0898895" stopColor="#92A5C1"/>
    <stop offset="1" stopColor="#E1E8F5"/>
    </linearGradient>
    <linearGradient id="paint3_linear_5_4858" x1="22.9397" y1="5.43456" x2="25.1079" y2="3.37597" gradientUnits="userSpaceOnUse">
    <stop stopColor="#E1E8F5"/>
    <stop offset="0.157303" stopColor="#C7D6EE"/>
    <stop offset="1" stopColor="#E1E8F5"/>
    </linearGradient>
    <linearGradient id="paint4_linear_5_4858" x1="51.0017" y1="5.51594" x2="48.706" y2="3.33625" gradientUnits="userSpaceOnUse">
    <stop stopColor="#E1E8F5"/>
    <stop offset="0.157303" stopColor="#C7D6EE"/>
    <stop offset="1" stopColor="#E1E8F5"/>
    </linearGradient>
    <linearGradient id="paint5_linear_5_4858" x1="12.5097" y1="10.9929" x2="19.969" y2="3.39811" gradientUnits="userSpaceOnUse">
    <stop stopColor="#476886"/>
    <stop offset="0.129211" stopColor="#92A5C1"/>
    <stop offset="1" stopColor="#E1E8F5"/>
    </linearGradient>
    <linearGradient id="paint6_linear_5_4858" x1="2.99306" y1="21.5302" x2="2.12427" y2="20.267" gradientUnits="userSpaceOnUse">
    <stop stopColor="#002334"/>
    <stop offset="0.106735" stopColor="white"/>
    <stop offset="0.185394" stopColor="#91AFC8"/>
    <stop offset="0.241577" stopColor="#002334"/>
    <stop offset="0.533707" stopColor="#5E738F"/>
    <stop offset="0.758423" stopColor="#002334"/>
    <stop offset="1" stopColor="#002334"/>
    </linearGradient>
    <linearGradient id="paint7_linear_5_4858" x1="9.34863" y1="12.7209" x2="2.54508" y2="16.2462" gradientUnits="userSpaceOnUse">
    <stop stopColor="#91AFC8"/>
    <stop offset="1" stopColor="#002334"/>
    </linearGradient>
    <linearGradient id="paint8_linear_5_4858" x1="13.0785" y1="23.3358" x2="12.571" y2="20.4054" gradientUnits="userSpaceOnUse">
    <stop stopColor="#A5B9CC"/>
    <stop offset="1" stopColor="#002334"/>
    </linearGradient>
    <linearGradient id="paint9_linear_5_4858" x1="23.5808" y1="24.5467" x2="24.4541" y2="24.5467" gradientUnits="userSpaceOnUse">
    <stop stopColor="#5E738F"/>
    <stop offset="0.48877" stopColor="#91AFC8"/>
    <stop offset="1" stopColor="#91AFC8"/>
    </linearGradient>
    <linearGradient id="paint10_linear_5_4858" x1="49.8855" y1="24.4465" x2="48.8043" y2="24.4465" gradientUnits="userSpaceOnUse">
    <stop stopColor="#5E738F"/>
    <stop offset="0.320221" stopColor="#91AFC8"/>
    <stop offset="1" stopColor="#91AFC8"/>
    </linearGradient>
    <linearGradient id="paint11_linear_5_4858" x1="41.7622" y1="29.4977" x2="44.6406" y2="24.4217" gradientUnits="userSpaceOnUse">
    <stop stopColor="#A5B9CC"/>
    <stop offset="1" stopColor="#002334"/>
    </linearGradient>
    <linearGradient id="paint12_linear_5_4858" x1="29.3001" y1="24.7451" x2="32.2008" y2="29.8605" gradientUnits="userSpaceOnUse">
    <stop stopColor="#002334"/>
    <stop offset="0.775284" stopColor="#A5B9CC"/>
    <stop offset="1" stopColor="#A5B9CC"/>
    </linearGradient>
    <linearGradient id="paint13_linear_5_4858" x1="17.458" y1="5.49031" x2="30.4121" y2="28.3347" gradientUnits="userSpaceOnUse">
    <stop stopColor="#FFEFCF"/>
    <stop offset="0.460678" stopColor="#FFE4A6"/>
    <stop offset="1" stopColor="#BC956E"/>
    </linearGradient>
    <linearGradient id="paint14_linear_5_4858" x1="54.5299" y1="24.725" x2="58.1891" y2="20.5722" gradientUnits="userSpaceOnUse">
    <stop stopColor="#5E738F"/>
    <stop offset="0.606735" stopColor="#91AFC8"/>
    <stop offset="1" stopColor="#C7DFF4"/>
    </linearGradient>
    <linearGradient id="paint15_linear_5_4858" x1="58.2601" y1="2.45258" x2="63.0841" y2="10.9597" gradientUnits="userSpaceOnUse">
    <stop stopColor="#E1E8F5"/>
    <stop offset="0.814606" stopColor="#7F97B4"/>
    <stop offset="0.904495" stopColor="#476886"/>
    <stop offset="1" stopColor="#002334"/>
    </linearGradient>
    <linearGradient id="paint16_linear_5_4858" x1="64.0079" y1="21.6994" x2="71.8694" y2="7.83568" gradientUnits="userSpaceOnUse">
    <stop stopColor="#8CA0B5"/>
    <stop offset="0.140442" stopColor="#E1E8F5"/>
    <stop offset="0.814606" stopColor="#7F97B4"/>
    <stop offset="0.904495" stopColor="#476886"/>
    <stop offset="1" stopColor="#002334"/>
    </linearGradient>
    <linearGradient id="paint17_linear_5_4858" x1="61.2154" y1="21.9315" x2="64.0504" y2="20.265" gradientUnits="userSpaceOnUse">
    <stop stopColor="#002334"/>
    <stop offset="0.814606" stopColor="#A5B9CC"/>
    <stop offset="0.887634" stopColor="#002334"/>
    <stop offset="1" stopColor="#002334"/>
    </linearGradient>
    <linearGradient id="paint18_linear_5_4858" x1="9.64838" y1="15.7736" x2="18.8377" y2="25.1297" gradientUnits="userSpaceOnUse">
    <stop stopColor="#D4BC7B"/>
    <stop offset="0.331467" stopColor="#7F5F39"/>
    <stop offset="1" stopColor="#D4BC7B"/>
    </linearGradient>
    <linearGradient id="paint19_linear_5_4858" x1="59.3026" y1="17.0833" x2="52.7389" y2="23.7661" gradientUnits="userSpaceOnUse">
    <stop stopColor="#D4BC7B"/>
    <stop offset="0.331467" stopColor="#7F5F39"/>
    <stop offset="1" stopColor="#D4BC7B"/>
    </linearGradient>
    <linearGradient id="paint20_linear_5_4858" x1="64.8981" y1="2.78336" x2="54.2889" y2="13.5852" gradientUnits="userSpaceOnUse">
    <stop stopColor="#D4BC7B"/>
    <stop offset="0.331467" stopColor="#7F5F39"/>
    <stop offset="1" stopColor="#D4BC7B"/>
    </linearGradient>
    <linearGradient id="paint21_linear_5_4858" x1="24.7239" y1="23.1208" x2="26.1351" y2="23.1208" gradientUnits="userSpaceOnUse">
    <stop stopColor="#D3BD8B"/>
    <stop offset="1" stopColor="#7F5F39"/>
    </linearGradient>
    <linearGradient id="paint22_linear_5_4858" x1="48.724" y1="23.0995" x2="47.3126" y2="23.0995" gradientUnits="userSpaceOnUse">
    <stop stopColor="#D3BD8B"/>
    <stop offset="1" stopColor="#7F5F39"/>
    </linearGradient>
    <linearGradient id="paint23_linear_5_4858" x1="29.9266" y1="23.6971" x2="32.4794" y2="28.1988" gradientUnits="userSpaceOnUse">
    <stop stopColor="#7F5F39"/>
    <stop offset="0.646072" stopColor="#D3BD8B"/>
    <stop offset="0.887634" stopColor="#FFE4A6"/>
    <stop offset="1" stopColor="#FFE4A6"/>
    </linearGradient>
    <linearGradient id="paint24_linear_5_4858" x1="43.5404" y1="23.7192" x2="40.7516" y2="28.6371" gradientUnits="userSpaceOnUse">
    <stop stopColor="#7F5F39"/>
    <stop offset="0.455063" stopColor="#D3BD8B"/>
    <stop offset="0.887634" stopColor="#FFE4A6"/>
    <stop offset="1" stopColor="#FFE4A6"/>
    </linearGradient>
    <linearGradient id="paint25_linear_5_4858" x1="28.0584" y1="5.76231" x2="34.0064" y2="-0.293655" gradientUnits="userSpaceOnUse">
    <stop stopColor="#D4BC7B"/>
    <stop offset="0.0393219" stopColor="#7F5F39"/>
    <stop offset="0.106735" stopColor="#D1AC75"/>
    <stop offset="1" stopColor="#D4BC7B"/>
    </linearGradient>
    <linearGradient id="paint26_linear_5_4858" x1="45.4757" y1="5.88438" x2="39.4238" y2="-0.277383" gradientUnits="userSpaceOnUse">
    <stop stopColor="#D4BC7B"/>
    <stop offset="0.0393219" stopColor="#7F5F39"/>
    <stop offset="0.106735" stopColor="#D1AC75"/>
    <stop offset="1" stopColor="#D4BC7B"/>
    </linearGradient>
    <linearGradient id="paint27_linear_5_4858" x1="24.0472" y1="6.75046" x2="26.2617" y2="4.64789" gradientUnits="userSpaceOnUse">
    <stop stopColor="#FFE292"/>
    <stop offset="0.157303" stopColor="#D1AC75"/>
    <stop offset="1" stopColor="#FFE292"/>
    </linearGradient>
    <linearGradient id="paint28_linear_5_4858" x1="49.5367" y1="6.72121" x2="47.2281" y2="4.52935" gradientUnits="userSpaceOnUse">
    <stop stopColor="#FFE292"/>
    <stop offset="0.157303" stopColor="#D1AC75"/>
    <stop offset="1" stopColor="#FFE292"/>
    </linearGradient>
    <linearGradient id="paint29_linear_5_4858" x1="13.8508" y1="12.0207" x2="20.7602" y2="4.98588" gradientUnits="userSpaceOnUse">
    <stop stopColor="#7F5F39"/>
    <stop offset="1" stopColor="#BD9B6A"/>
    </linearGradient>
    <linearGradient id="paint30_linear_5_4858" x1="64.6014" y1="13.2965" x2="67.541" y2="16.2895" gradientUnits="userSpaceOnUse">
    <stop stopColor="#7F5F39"/>
    <stop offset="1" stopColor="#D4BC7B"/>
    </linearGradient>
    <linearGradient id="paint31_linear_5_4858" x1="63.6079" y1="20.7245" x2="70.028" y2="8.13392" gradientUnits="userSpaceOnUse">
    <stop stopColor="#A78A5E"/>
    <stop offset="0.764038" stopColor="#7F5F39"/>
    <stop offset="0.915726" stopColor="#674C2B"/>
    <stop offset="1" stopColor="#674C2B"/>
    </linearGradient>
    <linearGradient id="paint32_linear_5_4858" x1="58.0525" y1="23.569" x2="39.6727" y2="1.26715" gradientUnits="userSpaceOnUse">
    <stop stopColor="#FFEFCF"/>
    <stop offset="0.460678" stopColor="#FFE4A6"/>
    <stop offset="1" stopColor="#BC956E"/>
    </linearGradient>
    <linearGradient id="paint33_linear_5_4858" x1="2.78135" y1="19.5778" x2="9.15479" y2="13.0887" gradientUnits="userSpaceOnUse">
    <stop stopColor="#7F5F39"/>
    <stop offset="1" stopColor="#E9CF98"/>
    </linearGradient>
    <linearGradient id="paint34_linear_5_4858" x1="1.63428" y1="19.1909" x2="10.217" y2="10.4523" gradientUnits="userSpaceOnUse">
    <stop stopColor="#7F5F39"/>
    <stop offset="1" stopColor="#E4CB90"/>
    </linearGradient>
    <clipPath id="clip0_5_4858">
    <rect width="71.888" height="35.944" fill="white" transform="translate(0.804199 0.943848)"/>
    </clipPath>
    </defs>
    </svg>
    
}