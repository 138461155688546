import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { title } from "process";

export const SectionSixComponent = ()=>{
    const history = useHistory();
  
    useEffect(()=>{
      
    },[])
    return <section className="min-height-100 p-5 text-center card-blue-1 mp-0 mm-0 ">
    <div className="text-center gap-5 p-5 mp-2 anim_section" >
    <div className="title-text black fs-48 m-title-text normal">Our Impact Goals.</div>
    <div className="fs-24 ff-inter my-3 m-sub-text gray">
    We are committed each day to improving people's lives, sustaining <br/>their livelihoods, and promoting a more sustainable society.
    </div>
    <div className="row my-5" >
        {[
          {icon:<WalletIcon />,value:"100% ",title:"Financial Inclusion",description:"We improve customers' access to vehicle ownership and increased earnings through our platform, empowering them for a financially inclusive future."},  
          {icon:<GrowthIcon />,value:"70%",title:"Socio-Economic Growth",description:"GearUp aims to empower young customers in gaining employment or starting businesses through vehicle financing, fostering socio-economic growth."},  
          {icon:<HeartIcon />,value:"60%",title:"Sustainability",description:"As a green company, we are focused on reducing CO2 emissions by 60% and supporting ride-sharing businesses while investing in EVs."}
        ].map((a,i)=><div key={i} className="col-lg-4 col-xl-4 col-md-12 col-sm-12 my-5 mm-0">
          <div className="card-white-bordered1 p-5 mb-2" >
            {a.icon}
            <div className="fs-28 ff-inter black fw-normal mt-3 fw-500">{a.value}</div>
            <div className="fs-28 ff-inter black fw-normal mb-3 fw-500">{a.title}</div>
            <div className="fs-16 ff-inter gray">{a.description}</div>
        </div>  
        </div>)}
    </div>
    </div>
    </section>
}
export default SectionSixComponent;
const WalletIcon = ()=>{
    return <svg width="100" height="100" viewBox="0 0 113 112" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_i_5_4818)">
    <rect x="2.6665" y="2" width="108" height="108" rx="24" fill="#DBEAFE"/>
    <rect x="1.6665" y="1" width="110" height="110" rx="25" stroke="#EFF6FF" strokeWidth="2"/>
    <path d="M81.6665 41H64.1665C60.1883 41 56.3729 42.5804 53.5599 45.3934C50.7469 48.2064 49.1665 52.0218 49.1665 56C49.1665 59.9782 50.7469 63.7936 53.5599 66.6066C56.3729 69.4197 60.1883 71 64.1665 71H81.6665V76C81.6665 76.663 81.4031 77.2989 80.9343 77.7678C80.4654 78.2366 79.8295 78.5 79.1665 78.5H34.1665C33.5035 78.5 32.8676 78.2366 32.3987 77.7678C31.9299 77.2989 31.6665 76.663 31.6665 76V36C31.6665 35.337 31.9299 34.7011 32.3987 34.2322C32.8676 33.7634 33.5035 33.5 34.1665 33.5H79.1665C79.8295 33.5 80.4654 33.7634 80.9343 34.2322C81.4031 34.7011 81.6665 35.337 81.6665 36V41ZM64.1665 46H84.1665V66H64.1665C61.5143 66 58.9708 64.9464 57.0954 63.0711C55.2201 61.1957 54.1665 58.6522 54.1665 56C54.1665 53.3478 55.2201 50.8043 57.0954 48.9289C58.9708 47.0536 61.5143 46 64.1665 46ZM64.1665 53.5V58.5H71.6665V53.5H64.1665Z" fill="#3B82F6"/>
    </g>
    <defs>
    <filter id="filter0_i_5_4818" x="0.666504" y="0" width="112" height="112" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feMorphology radius="4" operator="erode" in="SourceAlpha" result="effect1_innerShadow_5_4818"/>
    <feOffset/>
    <feGaussianBlur stdDeviation="2"/>
    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0.231373 0 0 0 0 0.509804 0 0 0 0 0.964706 0 0 0 0.24 0"/>
    <feBlend mode="normal" in2="shape" result="effect1_innerShadow_5_4818"/>
    </filter>
    </defs>
    </svg>
    
}
const GrowthIcon =()=>{
    return <svg width="100" height="100" viewBox="0 0 112 112" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_i_5_4824)">
    <rect x="2" y="2" width="108" height="108" rx="24" fill="#DBEAFE"/>
    <rect x="1" y="1" width="110" height="110" rx="25" stroke="#EFF6FF" strokeWidth="2"/>
    <path d="M35.7425 70.65L45.5175 60.875L52.59 67.945L64.0175 56.52L68.5 61V48.5H56L60.4825 52.9825L52.59 60.875L45.52 53.8025L33.155 66.1675C31.7298 62.9675 30.9955 59.503 31 56C31 42.1925 42.1925 31 56 31C69.8075 31 81 42.1925 81 56C81 69.8075 69.8075 81 56 81C52.045 81.0026 48.146 80.0656 44.624 78.2661C41.1021 76.4667 38.058 73.8563 35.7425 70.65Z" fill="#3B82F6"/>
    </g>
    <defs>
    <filter id="filter0_i_5_4824" x="0" y="0" width="112" height="112" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feMorphology radius="4" operator="erode" in="SourceAlpha" result="effect1_innerShadow_5_4824"/>
    <feOffset/>
    <feGaussianBlur stdDeviation="2"/>
    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0.231373 0 0 0 0 0.509804 0 0 0 0 0.964706 0 0 0 0.24 0"/>
    <feBlend mode="normal" in2="shape" result="effect1_innerShadow_5_4824"/>
    </filter>
    </defs>
    </svg>
    
}
const HeartIcon = ()=>{
    return <svg width="100" height="100" viewBox="0 0 113 112" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_i_5_4830)">
    <rect x="2.33301" y="2" width="108" height="108" rx="24" fill="#DBEAFE"/>
    <rect x="1.33301" y="1" width="110" height="110" rx="25" stroke="#EFF6FF" strokeWidth="2"/>
    <path d="M49.658 54.75H55.083C58.0667 54.75 60.9282 55.9353 63.038 58.0451C65.1477 60.1548 66.333 63.0163 66.333 66H48.8305L48.833 68.5H68.833V66C68.8251 63.3399 68.0566 60.7376 66.618 58.5H73.833C76.1962 58.4994 78.5111 59.1686 80.5093 60.4301C82.5076 61.6916 84.1073 63.4938 85.123 65.6275C79.2105 73.43 69.638 78.5 58.833 78.5C51.9305 78.5 46.083 77.025 41.333 74.4375V51.1775C44.3745 51.6132 47.2467 52.8457 49.658 54.75ZM36.333 48.5C36.9453 48.5001 37.5364 48.7249 37.9939 49.1318C38.4515 49.5387 38.7439 50.0994 38.8155 50.7075L38.833 51V73.5C38.833 74.1631 38.5696 74.7989 38.1008 75.2678C37.6319 75.7366 36.996 76 36.333 76H31.333C30.67 76 30.0341 75.7366 29.5652 75.2678C29.0964 74.7989 28.833 74.1631 28.833 73.5V51C28.833 50.337 29.0964 49.7011 29.5652 49.2322C30.0341 48.7634 30.67 48.5 31.333 48.5H36.333ZM60.448 34.9375L61.333 35.825L62.218 34.94C62.7975 34.3556 63.4868 33.8913 64.2462 33.574C65.0056 33.2566 65.8202 33.0924 66.6433 33.0907C67.4664 33.089 68.2817 33.2499 69.0424 33.5641C69.8031 33.8784 70.4942 34.3398 71.0761 34.9219C71.6581 35.504 72.1193 36.1952 72.4333 36.956C72.7473 37.7168 72.908 38.5322 72.9061 39.3552C72.9042 40.1783 72.7397 40.9929 72.4221 41.7522C72.1046 42.5115 71.6401 43.2007 71.0555 43.78L61.333 53.5L51.608 43.775C51.0236 43.1955 50.5593 42.5062 50.242 41.7468C49.9246 40.9874 49.7604 40.1728 49.7587 39.3497C49.757 38.5266 49.9179 37.7114 50.2321 36.9506C50.5464 36.1899 51.0078 35.4988 51.5899 34.9169C52.1719 34.335 52.8632 33.8737 53.624 33.5597C54.3848 33.2457 55.2002 33.085 56.0232 33.0869C56.8463 33.0888 57.6609 33.2533 58.4202 33.5709C59.1795 33.8885 59.8686 34.3529 60.448 34.9375Z" fill="#3B82F6"/>
    </g>
    <defs>
    <filter id="filter0_i_5_4830" x="0.333008" y="0" width="112" height="112" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feMorphology radius="4" operator="erode" in="SourceAlpha" result="effect1_innerShadow_5_4830"/>
    <feOffset/>
    <feGaussianBlur stdDeviation="2"/>
    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0.231373 0 0 0 0 0.509804 0 0 0 0 0.964706 0 0 0 0.24 0"/>
    <feBlend mode="normal" in2="shape" result="effect1_innerShadow_5_4830"/>
    </filter>
    </defs>
    </svg>
    
}