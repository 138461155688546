
import "./style.css";
import { SectionYellowComponent } from "../../components/footer/sectionOne";
import SectionFiveComponent from "./sections/sectionFive";
import SectionFourComponent from "./sections/sectionFour";
import SectionOneComponent from "./sections/sectionOne";
import SectionSevenComponent from "./sections/sectionSeven";
import SectionSixComponent from "./sections/sectionSix";
import SectionThreeComponent from "./sections/sectionThree";
import SectionTwoComponent from "./sections/sectionTwo";
import { useEffect } from "react";
import HeaderComponent from "../../components/header";

const HomePageComponent = ()=>{
   
    useEffect(()=>{
        const hash = window.location.hash;
        if(!hash)
        {
        window.scrollTo({top:0,behavior:"smooth"})
        }
    },[])
    return <>
    <HeaderComponent  black={false} />
    <div className="prn">
        <SectionOneComponent />
        <SectionTwoComponent />
        <SectionThreeComponent />
        <SectionFourComponent />
        <SectionFiveComponent />
        <SectionSixComponent />
        <SectionSevenComponent />
        <SectionYellowComponent />
    </div>
    </>
}
export default HomePageComponent;