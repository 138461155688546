export const CupraLogo = ()=>{
    return <svg width="73" height="48" viewBox="0 0 73 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_5_5126)">
    <path d="M15.1982 0.422852C22.0655 2.81146 28.896 5.31032 35.7793 7.6507C36.0228 7.74486 36.264 7.74486 36.5074 7.6507C43.3793 5.29883 50.2052 2.82065 57.061 0.422852C54.6287 4.29975 52.1735 8.16287 49.6884 12.0053C48.54 11.5115 47.3572 11.1004 46.1997 10.6273C46.8703 9.44675 47.5915 8.29379 48.2713 7.11785C46.0573 8.23177 43.8042 9.2676 41.6062 10.4137C43.1427 10.9993 44.6815 11.5735 46.2066 12.1914C47.0564 12.5382 47.9682 13.2065 48.0256 14.2033C48.1611 15.3103 47.4055 16.2153 46.8703 17.1018C43.5584 22.2832 40.2649 27.4785 36.9369 32.6507C36.9553 31.2865 36.7991 29.9084 36.9599 28.5533C38.269 24.3985 39.5621 20.2391 40.7541 16.0499C40.9884 15.4275 40.2924 15.0944 39.8446 14.8762C38.5814 14.3181 37.3985 13.5786 36.1055 13.1009C34.794 13.6682 33.5354 14.3549 32.2493 14.9773C31.8519 15.161 31.2915 15.5239 31.5189 16.043C32.7017 20.2345 34.0086 24.3917 35.3085 28.5488C35.4532 29.9061 35.3154 31.2842 35.3223 32.6507C32.0471 27.5244 28.7789 22.3912 25.5083 17.2626C24.9387 16.3186 24.1119 15.354 24.2543 14.1757C24.3301 13.1858 25.2373 12.529 26.0871 12.1845C27.5984 11.5689 29.1326 11.0062 30.6553 10.4114C28.4619 9.2699 26.218 8.22488 24.0062 7.12015C24.6838 8.29608 25.405 9.44675 26.0733 10.6296C24.9089 11.0935 23.7306 11.523 22.5708 11.9984C20.0949 8.15139 17.6443 4.28597 15.1982 0.422852Z" fill="black"/>
    <path d="M29.75 47.3305C29.773 45.0659 29.7339 42.8037 29.7707 40.5391C33.496 40.5643 37.2236 40.5322 40.9489 40.5528C41.677 40.5276 42.5268 40.8928 42.6715 41.6782C42.761 42.4477 42.7404 43.2285 42.6853 43.998C42.6393 44.8478 41.7482 45.3622 40.9627 45.3071C37.9241 45.314 34.8855 45.3025 31.8446 45.3094C31.8469 45.9892 31.8492 46.6668 31.8584 47.3466C31.1556 47.365 30.4528 47.3558 29.75 47.3305ZM31.8377 42.0756C31.8469 42.6038 31.8515 43.1321 31.863 43.6626C34.7477 43.6351 37.6324 43.6879 40.5171 43.6374C41.0408 43.3342 40.6848 42.6061 40.7836 42.1192C37.8047 42.0182 34.8189 42.0986 31.8377 42.0756Z" fill="black"/>
    <path d="M0.566638 41.5844C0.865214 40.9091 1.63922 40.5325 2.3581 40.5485C6.00992 40.5394 9.66173 40.5531 13.3159 40.5439C13.3227 41.1687 13.325 41.7957 13.3296 42.4204C9.75131 42.4456 6.16839 42.3882 2.59236 42.4479C2.52576 42.5008 2.39484 42.6087 2.32824 42.6638C2.2869 43.5619 2.33743 44.4645 2.30068 45.3648C2.62452 45.4452 2.95295 45.5279 3.29057 45.5141C6.63463 45.5049 9.97869 45.5187 13.3227 45.5049C13.325 46.109 13.3273 46.713 13.3273 47.317C9.8248 47.4181 6.31768 47.3308 2.81515 47.3676C1.95617 47.402 0.890478 47.2367 0.550561 46.318C0.364524 45.5049 0.493142 44.662 0.458691 43.8375C0.470175 43.0887 0.359931 42.317 0.566638 41.5844Z" fill="#010101"/>
    <path d="M15.0703 45.6815C15.0634 43.9681 15.0588 42.2547 15.0795 40.5437C15.8282 40.546 16.5792 40.5414 17.3303 40.5551C17.3555 42.1973 17.3027 43.8418 17.3624 45.4839C20.1415 45.5391 22.9228 45.5069 25.7019 45.5023C25.8167 43.8533 25.7294 42.1996 25.7547 40.5505C26.5126 40.546 27.2706 40.5482 28.0285 40.5391C28.0446 42.2501 28.0492 43.9635 28.0331 45.6769C28.0423 46.5956 27.1718 47.3627 26.2738 47.3466C23.6647 47.4247 21.0533 47.3213 18.4419 47.3673C17.6886 47.365 16.9214 47.4109 16.1842 47.2249C15.5595 46.9906 15.0358 46.3728 15.0703 45.6815Z" fill="#010101"/>
    <path d="M44.4522 40.5435C48.1109 40.5526 51.7696 40.5435 55.4283 40.5458C56.2184 40.5297 57.1486 40.9408 57.3461 41.7745C57.4012 42.5393 57.3989 43.311 57.3438 44.0758C57.229 44.7189 56.6617 45.0566 56.1426 45.3551C56.6387 45.9844 57.0797 46.6528 57.5253 47.3188C56.6203 47.2614 55.2469 47.8035 54.7416 46.7791C54.4384 46.2968 54.1513 45.8053 53.8574 45.3184C51.4389 45.2977 49.0204 45.3092 46.602 45.3115C46.6065 45.9775 46.6088 46.6436 46.6157 47.312C45.8923 47.3763 45.1665 47.3786 44.4453 47.3303C44.4568 45.068 44.4361 42.8057 44.4522 40.5435ZM46.6249 41.9996C46.6065 42.5508 46.6065 43.1043 46.6088 43.6555C49.466 43.6349 52.3254 43.6946 55.1803 43.628C55.2515 43.5775 55.3939 43.4741 55.4674 43.4213C55.426 42.9573 55.7039 42.2568 55.1481 42.0272C52.3094 41.9674 49.466 42.0249 46.6249 41.9996Z" fill="#010101"/>
    <path d="M59.1383 42.2322C59.1084 41.1963 60.1902 40.4751 61.1502 40.5463C64.7745 40.544 68.3987 40.5532 72.023 40.5418C72.0345 42.804 72.0621 45.0686 71.9954 47.3309C71.3661 47.3585 70.7299 47.4182 70.1029 47.3401C69.8181 46.7453 70.0593 45.969 69.9904 45.3098C67.0781 45.3075 64.1659 45.3098 61.2536 45.3075C61.249 45.9873 61.2536 46.6695 61.2582 47.3516C60.5531 47.37 59.8503 47.3608 59.1475 47.3309C59.1681 45.6313 59.1038 43.9317 59.1383 42.2322ZM61.2628 42.0966C61.2536 42.618 61.2559 43.1394 61.2651 43.663C64.1682 43.6469 67.0735 43.6584 69.9789 43.6561C69.9835 43.1302 69.9858 42.6065 69.9927 42.0806C67.0827 42.0806 64.1727 42.0507 61.2628 42.0966Z" fill="#010101"/>
    </g>
    <defs>
    <clipPath id="clip0_5_5126">
    <rect width="71.888" height="47.3129" fill="white" transform="translate(0.300293 0.204102)"/>
    </clipPath>
    </defs>
    </svg>
    
    
}