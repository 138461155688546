import { NavLink, useHistory } from "react-router-dom";
import { BlackLogo, MenuIcon, WhiteLogo } from "../Logo";
import { AppRoutes } from "../../includes/constant";
import { RefObject, useEffect, useRef, useState } from "react";
import { CloseBtn } from "../../pages/apply/forms/formFour";
interface HeaderProps {
black?:boolean;
}
const HeaderComponent = (props:HeaderProps)=>{
  const history = useHistory()
  const [toggleMenu,setToggleMenu] = useState<boolean>(false)
  const thisTopBar = useRef()  as RefObject<HTMLDivElement>
  const [scrollPosition, setScrollPosition] = useState<number>(0);
    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
        var pathName = window.location.pathname;
        var name:boolean = false;
        if([AppRoutes.Drive,AppRoutes.Apply,AppRoutes.GetIntorch,String(AppRoutes.CarDetails).replace(":id","")].includes(pathName))
          {
            name = true;
          }
        // console.log("position:",position)
        // animate nav bar
    };
    
    useEffect(() => {
      
        window.addEventListener('scroll', handleScroll, { passive: true });
        const observer2 = new IntersectionObserver((entries)=>{
        // console.log(entries)
        entries.forEach((entry,i)=>{
      console.log(entry.target.className)
      if(entry.isIntersecting)
        {
                if(entry.target.className.includes("anim-img"))
                  {
                    entry.target.classList.toggle(`animate__zoomIn`,entry.isIntersecting) 
                  }else if(entry.target.className.includes("slide_right")){
                    entry.target.classList.toggle(`animate__backInLeft`,entry.isIntersecting)
                  }else  if(entry.target.className.includes("section-img"))
                    {
                    }else{
                    entry.target.classList.toggle(`animate__fadeInUp`,entry.isIntersecting)
                  }
                }    
          })
        },{
          rootMargin:"0px",
          threshold:[0,1]
        })
        const sections = document.querySelectorAll(".anim_section,.anim-img,.slide_right,section-img");
        sections.forEach((elem)=>{
          if(!elem.className.includes("section-img"))
            {
            elem.classList.add("animate__animated")
            }
            observer2.observe(elem)
        })
        return () => {
          window.removeEventListener('scroll', handleScroll);
      };
    },[]);
    useEffect(()=>{
    var prevHash = window.location.hash;
    if (String(prevHash).includes("faqs")) {
      setTimeout(()=>{
        var ele:HTMLElement | null = document.getElementById(String(prevHash).replace("#",""));   
        if(ele)
          {
       window.scrollTo(ele.offsetLeft,ele.offsetTop-80); 
          }
      },1000)
        }
    },[])
    return <nav 
    ref={thisTopBar}
    className={`top-bar `}  >
        <div className="row m-hide" >
            <div className="col-lg-5 d-flex gap-5 ps-5 justify-content-starts align-items-center m-hide">
              <NavLink 
              to={AppRoutes.Drive}
              className={props.black?"nav-item-black":"nav-item"}>
              Drive with Us
                </NavLink> 
                <a 
              href={"/#faqs"}
                className={props.black?"nav-item-black ":"nav-item"}>
              FAQs
                </a>  
            </div>
            <div className="col-lg-2 col-sm-12 col-md-12 d-flex justify-content-center align-items-center">
            <NavLink 
                to={"/"}
                >  
                {props.black?<BlackLogo />:<WhiteLogo />}
                </NavLink>
            </div>
            <div className="col-lg-5 pe-5 d-flex justify-content-end align-items-center gap-5 m-hide" >
            <NavLink 
              to={AppRoutes.Investors}
                className={props.black?"nav-item-black":"nav-item"} >
              For Investors
                </NavLink> 
                <NavLink 
              to={AppRoutes.Apply}
                className={`nav-btn ${props.black?"nav-item-black bordered-black-btn ":"nav-item bordered-btn"}`}>
              <div className="nav-item-inner" >
                <div style={{color:!props.black?"white":"black"}}> APPLY TO DRIVE</div>
                <div > APPLY TO DRIVE</div>
             </div>
                </NavLink> 
            </div>
        </div>
        <div 
        onClick={()=>setToggleMenu(!toggleMenu)}
        className="lg-hide d-flex align-items-center justify-content-center d-block d-sm-none ">
         <span className="left" >
          {!toggleMenu?<MenuIcon white={!props.black} />:<CloseBtn size={25} white={!props.black} />}
          </span>
          <span className="ps-3">
          <NavLink 
                to={"/"}
                >  
                {props.black?<BlackLogo />:<WhiteLogo />}
                </NavLink>
          </span>
        </div>
        {toggleMenu && <div className="p-abs" style={{width:"90%"}}>
        <ul className="drop-down no-deco p-0" style={{width:"100%"}}>
        {[
          {title:"Drive with Us",route:AppRoutes.Drive},
          {title:"FAQs",route:AppRoutes.Faqs},
          {title:"For Investors",route:AppRoutes.Investors},
          {title:"Apply to drive",route:AppRoutes.Apply},
        ].map((a,i)=><li key={i}>
          <div className="no-deco black ps-3" onClick={()=>{
            if(a.route === "/faqs")
              {
                window.location.href = "/#faqs"
                setToggleMenu(false);
                return ;
              }
            history.push(a.route);
            // window.location.href = a.route
            setToggleMenu(false);
          }} >{a.title}</div>
        </li>)}
        </ul>
        </div>}
    </nav>
}
export default HeaderComponent;


