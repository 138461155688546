interface BlackBtnProps {
    text:string;
    loading?:boolean;
    bold?:boolean;
    filled?:boolean;
    onClick:()=>void;
    width?:number
}
export const BlackBtn = (props:BlackBtnProps)=>{
    return <button 
    onClick={props.onClick}
    className={`btn ${!props.loading ?"black":"inActive"}-btn ${props.filled && "btn-filled"}`}
    style={{minWidth:props?.width?props.width:"auto"}}
    >
    {props.loading && <div className="spinner-border text-warning" role="status">
    </div>}
    {props.text}
    </button>
}