export const ODBIcon = ()=>{
    return <svg width="97" height="32" viewBox="0 0 97 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_5_4597)">
    <path d="M88.4917 4.36365L80.4917 12.3636C80.4917 7.97149 84.0995 4.36365 88.4917 4.36365Z" fill="#01A32A"/>
    <path d="M80.4917 12.3636L88.4917 20.3636C84.0995 20.4421 80.4917 16.8342 80.4917 12.3636Z" fill="#01A32A"/>
    <path d="M88.4917 20.4421L96.4917 12.4421C96.5701 16.8342 92.9623 20.4421 88.4917 20.4421Z" fill="#01A32A"/>
    <path d="M96.5701 12.3636L88.4917 4.36365C92.9623 4.36365 96.5701 7.97149 96.5701 12.3636Z" fill="#01A32A"/>
    <path d="M0.859375 15.4061C0.859375 12.3473 1.56526 9.99434 3.05545 8.34728C6.428 4.97473 11.8398 4.97473 15.2123 8.34728C16.7025 9.99434 17.4084 12.3473 17.4084 15.4061V20.661C17.4084 23.7983 16.7025 26.1512 15.2123 27.7983C11.8398 31.0924 6.428 31.0924 3.05545 27.7983C1.56526 26.1512 0.859375 23.7983 0.859375 20.661V15.4061ZM9.09467 27.6414C10.6633 27.7198 12.2319 27.014 13.1731 25.759C14.1143 24.5041 14.5064 22.7787 14.5064 20.4257V15.563C14.5849 13.759 14.1143 11.9551 13.1731 10.3865C11.4476 8.19042 8.15349 7.79826 5.87898 9.60219C5.56526 9.83748 5.25153 10.0728 5.01624 10.4649C4.07506 12.0336 3.60447 13.8375 3.6829 15.6414V20.5041C3.6829 22.7787 4.15349 24.5826 5.01624 25.8375C5.95741 27.0924 7.52604 27.7198 9.09467 27.6414Z" fill="black"/>
    <path d="M21.8008 6.30804H29.4871C32.389 6.30804 34.5067 7.01393 35.84 8.42569C37.1733 9.83746 37.8792 12.0335 37.8792 15.0924V21.1316C37.8792 24.1904 37.1733 26.3865 35.84 27.7982C34.5067 29.21 32.389 29.9159 29.4871 29.9159H21.8008V27.2492H23.84V8.89628H21.8008V6.30804ZM29.1733 27.1708C30.0361 27.1708 30.9773 27.0924 31.84 26.8571C32.5459 26.7002 33.1733 26.308 33.7224 25.8375C34.2714 25.2884 34.5851 24.661 34.8204 23.8767C35.0557 22.8571 35.2125 21.8375 35.1341 20.8178V15.2492C35.1341 14.2296 35.0557 13.21 34.8204 12.1904C34.6635 11.4845 34.2714 10.7786 33.7224 10.2296C33.1733 9.75902 32.5459 9.36687 31.84 9.21001C30.9773 8.97471 30.0361 8.89628 29.1733 8.89628H26.6635V27.1708H29.1733Z" fill="black"/>
    <path d="M41.8008 27.1708H43.84V8.89628H41.8008V6.30804H51.291C52.3106 6.30804 53.3302 6.46491 54.2714 6.77863C55.0557 7.09236 55.84 7.48451 56.4675 8.03353C57.0949 8.58255 57.4871 9.21001 57.8008 9.99432C58.1145 10.7786 58.2714 11.5629 58.2714 12.4257V12.8178C58.2714 13.4453 58.1929 13.9943 57.9576 14.6218C57.8008 15.1708 57.4871 15.6414 57.0949 16.0335C56.7027 16.4257 56.3106 16.8178 55.84 17.1316C55.3694 17.4453 54.8204 17.6806 54.2714 17.8375V18.2296C55.3694 18.5433 56.389 19.1708 57.0949 20.0335C57.8792 20.8963 58.2714 22.0728 58.2714 23.2492V23.6414C58.2714 24.5041 58.1145 25.2884 57.8008 26.0728C57.4871 26.7786 57.0165 27.4845 56.4675 28.0335C55.84 28.5826 55.0557 29.0531 54.2714 29.2884C53.3302 29.6022 52.3106 29.759 51.291 29.759H41.8008V27.1708ZM46.6635 16.7394H51.2125C52.389 16.8178 53.4871 16.4257 54.4282 15.7198C55.2125 15.0139 55.6831 13.9159 55.6047 12.8963V12.5826C55.6831 11.5629 55.2125 10.5433 54.3498 9.91589C53.4086 9.21001 52.3106 8.89628 51.1341 8.89628H46.6635V16.7394ZM46.6635 27.1708H51.1341C52.3106 27.2492 53.4086 26.8571 54.3498 26.1512C55.2125 25.5237 55.6047 24.5041 55.6047 23.4061V23.1708C55.6831 22.0728 55.2125 21.0531 54.4282 20.2688C53.5655 19.5629 52.389 19.1708 51.2125 19.2492H46.6635V27.1708Z" fill="black"/>
    <path d="M75.7615 23.9551H65.7223L63.9968 29.759H60.938L68.0752 6.22961H73.4086L80.5458 29.759H77.487L75.7615 23.9551ZM70.5066 7.48452L66.4282 21.2884H74.9772L70.8988 7.48452H70.5066Z" fill="black"/>
    </g>
    <defs>
    <clipPath id="clip0_5_4597">
    <rect width="95.6863" height="32" fill="white" transform="translate(0.859375)"/>
    </clipPath>
    </defs>
    </svg>
}