import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import './App.css';
import './AppMedia.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import HomePageComponent from './pages/homePage';
import DriveWithUSComponent  from "./pages/drive";
import HeaderComponent from './components/header';
import { AppRoutes } from "./includes/constant";
import CarDetailsPage from "./pages/carDetails";
import ApplyToDrive from "./pages/apply";
import InvestorScreenComponent from "./pages/investors";
import GetInTorchComponent  from "./pages/investors/getIntorch"
function App() {
  return (
   <Router>
    <div className="neutral">
    <Switch  >
    <Route
    path={AppRoutes.Apply}
     component={ApplyToDrive} />
      <Route
    path={AppRoutes.GetIntorch}
     component={GetInTorchComponent} />
        <Route
    path={AppRoutes.Investors}
     component={InvestorScreenComponent} />
     <Route
    path={AppRoutes.Drive}
     component={DriveWithUSComponent} />
      <Route
    path={AppRoutes.CarDetails}
     component={CarDetailsPage} />
    <Route
    path='*'
     component={HomePageComponent} />
     <Route
    path='/'
     component={HomePageComponent} />
     </Switch>
     <ToastContainer />
     </div>
   </Router>
  );
}

export default App;
