import { NavLink, useHistory, useLocation } from "react-router-dom"
import { AppRoutes, SocialLinks } from "../../includes/constant"

export const SectionBlackComponent = ()=>{
    const history = useHistory();
    const location = useLocation()
    const switchSection = String(location.pathname).includes("investors")
    return <div >
    <div  className="card-black p-5 p-rel" style={{overflow:"hidden"}}>
    <div className="p-abs" style={{right:0,top:0}}>
        <svg width="1512" height="292" viewBox="0 0 1512 292" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_5_5825)">
<g opacity="0.2">
<mask id="path-1-inside-1_5_5825" fill="white">
<path d="M1553.69 -87.2151L836.356 371.285L962.756 444.262L1673.5 -18.0405"/>
</mask>
<path d="M836.356 371.285L834.74 368.757L830.581 371.416L834.856 373.883L836.356 371.285ZM962.756 444.262L961.256 446.86L962.849 447.78L964.392 446.777L962.756 444.262ZM1552.07 -89.7429L834.74 368.757L837.971 373.813L1555.3 -84.6874L1552.07 -89.7429ZM834.856 373.883L961.256 446.86L964.256 441.664L837.856 368.687L834.856 373.883ZM964.392 446.777L1675.14 -15.5257L1671.86 -20.5553L961.12 441.748L964.392 446.777Z" fill="#A3A3A3" mask="url(#path-1-inside-1_5_5825)"/>
</g>
</g>
<defs>
<clipPath id="clip0_5_5825">
<rect width="1512" height="292" fill="white"/>
</clipPath>
</defs>
</svg>


    </div>
    {switchSection?<>
        <div className="text-start play fs-40 white">
        Join our reputable partners  </div>
        <button
        onClick={()=>history.push(AppRoutes.GetIntorch)}
        className="yellow-btn my-3" style={{backgroundColor:"white"}}>
    GET IN TOUCH
    </button>
    </>:<>
    <div className="text-center play fs-40 white mo-fs-20">
        Find out how you can own a vehicle and drive with our <br/>partner platforms in just 72 hours.
    </div>
  
    <div className="py-3 zIndex-9 p-rel" >
        <button 
        onClick={()=>{
            history.push(AppRoutes.Apply)
        }}
        className="yellow-btn" style={{width:"100%"}}>
        APPLY TO DRIVE
        </button>
    </div>
    </>}
    </div>
    <div className="p-5">
    <div className="row">
        <div className="col-lg-4">
            <div className="sub-title-1 pb-3" >GearUp by Treepz</div>
            <div className="ff-inter fs-20 mo-fs-16 mb-3">Treepz is the corporate mobility solution for  <br/>Employee Transportation, Conference <br/>Shuttling and, Vehicle Rental.</div>
        </div>
        <div className="col-lg-4">
            <div className="sub-title-1 mb-3" >GearUp by Treepz</div>
            <ul className="p-0 m-0">
                {[
                {title:"Drive with us",link:AppRoutes.Drive},
                {title:"For Investors",link:AppRoutes.Investors},
                {title:"For Customers",link:AppRoutes.GetStarted},
                {title:"FAQs",link:AppRoutes.Faqs}
                ].map((a,i)=><li key={i} className="no-deco black fs-16 mb-3" ><NavLink className="no-deco black " to={a.link} >{a.title}</NavLink></li>)}
            </ul>
        </div>
        <div className="col-lg-4">
            <div className="sub-title-1 mb-3" >FOLLOW US</div>
            <ul className="p-0 m-0">
                {[
                {title:"Instagram",link:SocialLinks.instagram},
                {title:"X (Formerly Twitter)",link:SocialLinks.x},
                {title:"Facebook",link:SocialLinks.facebook},
                {title:"TikTok",link:SocialLinks.ticktok}
            ].map((a,i)=><li key={i} className="no-deco black fs-16 mb-3" ><NavLink className="no-deco black " to={a.link} >{a.title}</NavLink></li>)}
            </ul>
        </div>
    </div>
    </div>
    <div className="row m-5 mm-0">
        <div className="col-lg-4 col-sm-3 m-col-2 col-md-6 fs-30 play black fw-bold mo-w-45" >
        GearUp
        </div>
        <div className="col-lg-4 m-hide  fs-14 ff-inter gray text-center" >
        © 2024 Treepz, Inc.
        </div>
        <div 
         onClick={()=>{
          window.location.href = "https://treepz.com/privacy"
        }}
        className="col-lg-4  btn p-0 m-0 col-sm-2 col-md-3 m-col-6 fs-14 ff-inter gray text-end mo-w-45 mmt-17" >
        Privacy Policy 
        </div>
    </div>
    </div>
}