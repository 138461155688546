import { NavLink, useHistory } from "react-router-dom"
import { AppRoutes, SocialLinks } from "../../includes/constant"

export const SectionYellowComponent = ()=>{
    const history = useHistory()
    return <div >
    <div  className="card-orange p-5">
        <div className="text-center play fs-40 mo-fs-30">
        Find out how you can own a vehicle and drive with our <br/>partner platforms in just 72 hours.
    </div>
    <div className="py-3 p-rel" >
        <button 
        onClick={()=>{
            window.location.href = AppRoutes.Drive;
        }}
        className="white-btn">
        GET STARTED
        </button>
    </div>
    </div>
    <div className="p-5">
    <div className="row">
        <div className="col-lg-4 mb-3">
            <div className="sub-title-1 pb-3" >GearUp by Treepz</div>
            <div className="ff-inter fs-20">Treepz is the corporate mobility solution for  <br/>Employee Transportation, Conference <br/>Shuttling and, Vehicle Rental.</div>
        </div>
        <div className="col-lg-4 mb-3">
            <div className="sub-title-1 mb-3" >GearUp by Treepz</div>
            <ul className="p-0 m-0">
                {[
                {title:"Drive with us",link:AppRoutes.Drive},
                {title:"For Investors",link:AppRoutes.Investors},
                {title:"For Customers",link:AppRoutes.GetStarted},
                {title:"FAQs",link:AppRoutes.Faqs}
                ].map((a,i)=><li key={i} className="no-deco black fs-16 mb-3" ><NavLink className="no-deco black " to={a.link} >{a.title}</NavLink></li>)}
            </ul>
        </div>
        <div className="col-lg-4 mb-3">
            <div className="sub-title-1 mb-3" >FOLLOW US</div>
            <ul className="p-0 m-0">
                {[
                {title:"Instagram",link:SocialLinks.instagram},
                {title:"X (Formerly Twitter)",link:SocialLinks.x},
                {title:"Facebook",link:SocialLinks.facebook},
                {title:"TikTok",link:SocialLinks.ticktok}
                ].map((a,i)=><li key={i} className="no-deco black fs-16 mb-3" ><a className="no-deco black " href={a.link} >{a.title}</a></li>)}
            </ul>
        </div>
    </div>
    </div>
    <div className="row m-5 mm-0">
        <div className="col-lg-4 col-sm-3 col-md-3 fs-30 play black fw-bold mo-w-45" >
        GearUp
        </div>
        <div className="col-lg-4 m-hide  fs-14 ff-inter gray text-center" >
        © 2024 Treepz, Inc.
        </div>
        <div
        onClick={()=>{
            window.location.href = "https://treepz.com/privacy"
        }}

        className="col-lg-4 btn p-0 m-0 col-sm-2 col-md-3 fs-14 ff-inter gray text-end mo-w-45 mmt-17" >
        Privacy Policy
        </div>
    </div>
    </div>
}