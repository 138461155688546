import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { title } from "process";

export const SectionSevenComponent = ()=>{
    const history = useHistory();
  const [selectedIndex,setSelectedIndex] = useState<number | null>(null)
    useEffect(()=>{
      
    },[])
    return <div id="faqs" className="min-height-100 p-5 card-black mp-2">
      <div className="m-5 mm-0">
    <div className="play fs-38 white text-start">FAQs</div>
    <ul className="white no-deco fs-26 ff-inter mp-0" >
        {[
            {title:"GENERAL",sub:"What's GearUp Treepz and their mission",description:`GearUp Treepz is a mobility product of Treepz, committed to enhancing and supporting mobility entrepreneurs. Our mission is to democratize vehicle ownership and provide financial access for black immigrants based in Canada with limited resources through our revenue-based financing model. <br/><br/><b>Where is GearUp Treepz available?</b><br/><br/>
GearUp Treepz is currently available in the select Canadian Province of Ontario. Click here to view the available vehicles in your area.
`},
{title:"application & onboarding",sub:"What are the criteria to apply? ",description:`Before you can drive for GearUp Treepz, you must meet the following criteria;                      
<br/><br/>- Age 21 or above
<br/>- Valid Driver's License
<br/>- Certificate of Good Conduct
<br/>- Additional documents may be necessary. 
<br/>
<br/>
<b>What does the GearUp Treepz onboarding process entail? </b><br/>
These are the necessary stages a driver must undergo before gaining access to a vehicle and driving on our partner platform.
<br/>
<br/>
<b>How long does the onboarding process take? </b><br/>
It takes approximately five days, depending on the vehicle availability.
<br/><br/>
<b>Do I need a credit check?</b><br/>        
No, you don't need a credit check to qualify for a GearUp Treepz vehicle.
<br/><br/>
<b>How does this work?</b><br/>
Our plans provide an affordable path for drivers to own a car. GearUp Treepz drivers pay weekly fees covering drive-to-own, drive-to-earn, and flexible rental contracts. At the end of the contract period, the vehicle becomes theirs.
<br/><br/>
<b>What does the package include? </b><br/>
Our package comes with various benefits, however, they all depend on your product choice and location.
<br/><br/>
<b>What vehicles are available for selection? </b><br/>
We currently offer various car models ranging from Tesla to Mercedes Benz, Hyundai or Toyota Venza, depending on your location. Browse for more details here.
<br/><br/>
<b>Are there telematics installed in the vehicle?</b><br/>
Yes, a telematics device will be installed in your car to support any insurance claim processes and enable us to help you reduce your vehicle's running costs effectively while maintaining its value.

`},
 {title:"earnings & payments",sub:"",description:`<b>How does the payout work? </b><br/>
Depending on the product, payouts occur weekly or monthly.
<br/><br/>
<b>Any other additional fees?</b><br/>
No, there are no extra payments to be made.
<br/><br/>
<b>Am I allowed to take a holiday break? </b><br/>
Yes, you can. Just inform the customer success executive assigned to you, and they'll guide you through the necessary steps.
<br/><br/>
<b>Can I end the contract? </b><br/>
Yes, you can terminate the contract by returning the vehicle. However, please note that any down payments made towards owning the vehicle will not be refunded.
<br/><br/>
<b>What does remittance mean?</b><br/>
Remittance refers to the amount of money a driver pays towards owning a vehicle.
<br/><br/>
<b>When can I make a remittance? </b><br/>
Depending on the product, remittance could be made daily, weekly, or monthly.
<br/><br/>
<b>What happens if I fail to pay my remittance?</b><br/>
If you fail to pay your remittance, you risk losing your car. Initially, the driver’s vehicle gets immobilized, and if payments can not be made, it will be retrieved.
<br/><br/>
<b>Does GearUp Treepz take cash payments?</b><br/>
No, GearUp Treepz operates a "zero cash" policy. All payments are to be made directly to the GearUp Treepz account via the remittance portal.
`},
{title:"Insurance & maintenance",sub:"",description:`<b>Are GearUp Treepz vehicles insured?</b><br/>
 Yes, all our vehicles are protected by a comprehensive insurance policy.
<br/><br/>
<b>Can I travel with my vehicle to other countries? </b><br/>
No, your vehicle's insurance is valid only for operation within specified city/cities (geofence).
<br/><br/>
<b>Who takes care of the vehicle? </b><br/>
GearUp Treepz handles the maintenance of all vehicles, and drivers are required to attend monthly scheduled inspections.
`}
        ].map((a,i)=><li className="white py-2 no-deco uppercase b-item fs-20 mo-fs-14 mp-0" key={i}>
          <div  onClick={()=>{
            setSelectedIndex(selectedIndex === i?null:i)
          }}
          className="btn p-0 m-0 white"
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11 11V5H13V11L19 11V13L13 13V19H11L11 13H5V11H11Z" fill="white"/>
            </svg>
            <span className="px-2 fs-26 ff-inter">{a.title}</span>
            </div>
            {i === selectedIndex &&<div className="white fs-20 p-3 ps-5 ff-inter expand" style={{textTransform:"none"}}>
                <div className="fw-bold mb-2 ff-inter">{a.sub}</div>
                <p dangerouslySetInnerHTML={{__html: a.description.replace(/(<? *script)/gi, 'illegalscript')}} />
            </div>}
            </li>)}
    </ul>
    </div>
    </div>
}
export default SectionSevenComponent;
