/* eslint-disable jsx-a11y/alt-text */
import { useHistory } from "react-router-dom";
import Img1  from "../../../assets/images/sectionThreeImages/img1.png"
import Img2  from "../../../assets/images/sectionThreeImages/img2.png"
import Img3  from "../../../assets/images/sectionThreeImages/img3.png"
import Img4  from "../../../assets/images/sectionThreeImages/img4.png"
import { useEffect, useState } from "react";
import { YellowBtn } from "../../../components/yellowBtn";
import { AppRoutes } from "../../../includes/constant";
import { CarProps } from "../../../includes/types";
import Sedan from "../../../assets/images/cars/sedan.png";
import SUV from "../../../assets/images/cars/suv.png";
import CUOPE from "../../../assets/images/cars/coupe.png";
import EVS from "../../../assets/images/cars/evs.png";
const SectionFourComponent = ()=>{
    const history = useHistory();
    const [selectedCar,setSelectedCar] = useState<CarProps>()
    const list:string[] = [
    Img1,
    Img2,
    Img3,
    Img4
    ];
    
    const listOfCars:CarProps[] = [
        {
            model:"Sedan",
            images:[Sedan],
            logo:"",
            year:"2021",
            type:"SEDAN",
            backgroundColor:"#FEF8EB",
            btnBackgroundColor:"#F8B02B"
        }, {
            model:"Tesla",
            images:[EVS],
            logo:"",
            year:"2021",
            type:"EVS",
            backgroundColor:"#F7FEE7",
            btnBackgroundColor:"#84CC16"
        }, {
            images:[SUV],
            logo:"",
            year:"2021",
            type:"SUV",
            backgroundColor:"#F0FDFA",
            btnBackgroundColor:"#14B8A6"
        }, {
            images:[CUOPE],
            logo:"",
            year:"2021",
            type:"COUPE",
            backgroundColor:"rgba(254, 230, 222, 0.64)",
            btnBackgroundColor:"#FA6533"
        }
    ];
    useEffect(()=>{
        setSelectedCar(listOfCars[0])
    },[])
    return <section className="anim_section" >
<div className="container">
  <div  className="row m-5">
 <div className="col-lg-6">
    <div className="title-text black fs-48 m-title-text normal">Choose your vehicle.</div>
 </div>
 <div className="col-lg-6">
    <div className="ff-inter fs-24 m-sub-text gray">
    Explore the available vehicles and learn how to start driving with us in just 72 hours.
    </div>
 </div>
  </div> 
  
  <div className="card-green-1 car-w-1 m-hide" style={{backgroundColor:selectedCar?.backgroundColor}} >
  <div className="car-w-1-inner " >
    {selectedCar?.images?.filter((a,i)=>i === 0).map((a,i)=><img key={i} className="car-img-2" src={a} />)}
    </div>
    <div className="center gap-3" >
    {listOfCars.map((a,i)=><button
    onClick={()=>{
        setSelectedCar(a)  
    }}
    className="btn car-tab-btn" 
style={{backgroundColor:a.type === selectedCar?.type?"rgba(0, 0, 0, 0.12)":"transparent",borderColor:a.type === selectedCar?.type?"rgba(0, 0, 0, 0.08)":"transparent"}}
    key={i} >{a.type}</button>)}
    <button
    onClick={()=>{
      window.location.href =  (AppRoutes.Drive)
    }}
    className="btn car-tab-btn "
    style={{backgroundColor:selectedCar?.btnBackgroundColor}}
    
    >Find our more <Arrow /></button>
    </div> 
    </div>
    <CarTypeMobileSection /> 
  </div> 
    </section>
}
export default SectionFourComponent;

const Arrow = ()=>{
   return <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
   <path d="M5.5 15L15.5 5" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
   <path d="M7.375 5H15.5V13.125" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
   </svg>
   }
const CarTypeMobileSection = ()=>{
    const [selectedCar,setSelectedCar] = useState<CarProps>()
    const list:string[] = [
    Img1,
    Img2,
    Img3,
    Img4
    ];
    
    const listOfCars:CarProps[] = [
        {
            model:"Sedan",
            images:[Sedan],
            logo:"",
            year:"2021",
            type:"SEDAN",
            backgroundColor:"#FEF8EB",
            btnBackgroundColor:"#F8B02B"
        }, {
            model:"Tesla",
            images:[EVS],
            logo:"",
            year:"2021",
            type:"EVS",
            backgroundColor:"#F7FEE7",
            btnBackgroundColor:"#84CC16"
        }, {
            images:[SUV],
            logo:"",
            year:"2021",
            type:"SUV",
            backgroundColor:"#F0FDFA",
            btnBackgroundColor:"#14B8A6"
        }, {
            images:[CUOPE],
            logo:"",
            year:"2021",
            type:"COUPE",
            backgroundColor:"rgba(254, 230, 222, 0.64)",
            btnBackgroundColor:"#FA6533"
        }
    ];
    useEffect(()=>{
        setSelectedCar(listOfCars[0]);
        let index = 0
        const x = setInterval(()=>{
            if(index >= listOfCars.length - 1)
            {
                index = 0
                return  setSelectedCar(listOfCars[0]) 
            }
            index = index + 1;
            setSelectedCar(listOfCars[index])   
        },2500)
    return ()=>{
       clearInterval(x);
    }
    },[])
return <div className="lg-hide">
<div className="card-green-1 car-w-1 m-lg" style={{backgroundColor:selectedCar?.backgroundColor}} >
<div className="car-w-1-inner " >
  {selectedCar?.images?.filter((a,i)=>i === 0).map((a,i)=><img key={i} className="car-img-2" src={a} />)}
  </div>
  <div className="center gap-3" >
  <button
  
  className="btn car-tab-btn" 
style={{backgroundColor:"rgba(0, 0, 0, 0.12)",borderColor:"rgba(0, 0, 0, 0.08)"}}
>{selectedCar?.type}</button>
  <button
  onClick={()=>{
    window.location.href =  (AppRoutes.Drive)
  }}
  className="btn car-tab-btn"
  style={{backgroundColor:selectedCar?.btnBackgroundColor,fontSize:18}}
  >Find our more <Arrow /></button>
  </div> 
  </div>
<div className="d-flex justify-content-center align-items-conter gap-2 my-2 mt-3" >
{listOfCars.map((a,i)=><div
onClick={()=>setSelectedCar(a)}
key={i} className={`btn p-0 m-0 ${selectedCar?.type === a.type?"sec-div-dot-active":"sec-div-dot"}`}></div>)}
</div>
</div>
}