/* eslint-disable import/first */
import { HTMLInputTypeAttribute, RefObject, useEffect, useRef, useState } from "react";
import "./style.css";
import { ItemProps, formInputPros } from "../../includes/types";
import { ReturnAllNumbers } from "../../includes/functions";
interface BaseTexAreaProps {
onChange:(d:formInputPros)=>void;
value:string;
max?:number;
label?:string;
placeholder?:string;
required?:boolean;
name:string;
onCode?:(code:string)=>void;
}
import {Countries}  from "../../json/country-by-flag";
const BaseTexArea = (props:BaseTexAreaProps)=>{
    const [show,setShow] = useState<boolean>(false);
    return <div className="mb-3"

    >
    {props?.label &&<label className="baseInput-label ms-2">{props.label}</label>}
    <div className="baseInputTextArea">
        
        <textarea
        maxLength={props.max}
        onChange={(d)=>{
            var value = d.target.value;
            props.onChange({key:props.name,value:value})
        }}
        required={props.required}
        placeholder={props.placeholder}
        >
            {props.value}
        </textarea>
    </div>
<label className="sub-title-1 fs-12 ff-inter" style={{textTransform:"lowercase"}}>{String(props.value).length} of {props.max}</label>

    </div>
}
export default BaseTexArea;