/* eslint-disable jsx-a11y/alt-text */
import { useHistory } from "react-router-dom";
import BackgroundImage from "../../../assets/images/bck2.png"
import { YellowBtn } from "../../../components/yellowBtn";
import { AppRoutes } from "../../../includes/constant";
const SectionOneComponent = ()=>{
    const history = useHistory();
    return <section >
        <img src={BackgroundImage}
        className="backImg" />
        <div className="gradient-blind" ></div>
        
        <div className="h-bottom-div row " >
        <div className="col-lg-7 ">
<div className="title-text ms-5 mo-fs-30 animate__animated animate__fadeInLeft anim-z5s">
Join us. Invest.
</div>
<div className="white-text fss-24 mb-5 ms-5 mo-fs-16 animate__animated animate__fadeInLeft anim-z1s">
Ready to join us on our mission to empower mobility entrepreneurs and drive the future?
</div>
<div className="me-5 mb-5 ms-5 animate__animated animate__fadeInLeft anim-z15s" >
<YellowBtn 
onClick={()=>{
history.push(AppRoutes.GetIntorch);
}}
text="GET IN TORCH" filled/>
            </div>
            </div>
            <div className="col-5  p-5 d-flex align-items-end justify-content-end m-hide">
                <button 
                 onClick={()=>{
                    history.push(AppRoutes.Apply);
                    }}
                className="btn animate__animated animate__bounceInUp anim-z17s" 
                style={{position:"absolute",right:40}} >
                <svg width="84" height="84" viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="83.5" width="83" height="83" rx="41.5" transform="rotate(-90 0.5 83.5)" stroke="white"/>
<path d="M43.8339 49.6486L53.6679 39.8146L56.2603 42.407L42.0006 56.6666L27.7409 42.407L30.3333 39.8146L40.1673 49.6486V27.3333H43.8339V49.6486Z" fill="white"/>
</svg>

                </button>
            </div>
        </div>
    </section>
}
export default SectionOneComponent;