import { useHistory } from "react-router-dom";
import Img1  from "../../../assets/images/sectionThreeImages/img1.png"
import Img2  from "../../../assets/images/sectionThreeImages/img2.png"
import Img3  from "../../../assets/images/sectionThreeImages/img3.png"
import Img4  from "../../../assets/images/sectionThreeImages/img4.png"
import Img5  from "../../../assets/images/sectionThreeImages/img5.png"
import Img6  from "../../../assets/images/sectionThreeImages/img6.png"
import Img7  from "../../../assets/images/sectionThreeImages/img7.png"
import Img8  from "../../../assets/images/sectionThreeImages/img8.png"
import { useEffect } from "react";
import { YellowBtn } from "../../../components/yellowBtn";
import { AppRoutes } from "../../../includes/constant";
import { InView } from "react-intersection-observer";

const SectionThreeComponent = ()=>{
 
    const history = useHistory();
    const list:string[] = [
    Img1,
    Img2,
    Img3,
    Img4,
    Img5,
    Img6,
    Img7,
    Img8
    ]
    
    return <section className="minHeight-100">
  <div  className="container py-5 text-center">
  <div className="center p-rel text-center" >
  <div className="p-abs text-center" >
    <div className="title-text black fs-48 m-title-text normal no-text-shadow">A thriving <br/>community of riders</div>
    <div className="fs-24 gray m-sub-text no-text-shadow">
    Our customers love ❤️ us, and they <br/>move without financial burden or fear <br/>of debt and commitment
    </div>
    <div className="center mt-5" >
<YellowBtn
onClick={()=>history.push(AppRoutes.Apply)}
text="JOIN US" />
</div>
</div>
<div id="section-cards" className="no-deco center">
{list.reverse().map((a,i,self)=>{
    return   <InView key={i}
    triggerOnce={true}
    >
    {({ inView, ref, entry }) => (
    <div ref={ref} className={`section-img p-abs`}  id={inView?`img-${i}`:""} ><img  key={i} src={a}  /></div>
    )}
    </InView>
})}
</div>
  </div>
  </div>   
    </section>
}
export default SectionThreeComponent;
