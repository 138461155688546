export const VoxLogo = ()=>{
    return <svg width="55" height="55" viewBox="0 0 73 73" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_5_5139)">
    <path d="M36.9518 69.3424C19.1419 69.3424 4.55067 54.6797 4.55067 36.8698C4.55067 32.8643 5.26593 29.0735 6.62492 25.4972L25.5792 63.6204C25.7938 64.1211 26.1514 64.5502 26.7236 64.5502C27.2958 64.5502 27.6535 64.1211 27.868 63.6204L36.5942 44.0939C36.6657 43.8793 36.8088 43.6647 37.0233 43.6647C37.2379 43.6647 37.3094 43.8793 37.4525 44.0939L46.1786 63.6204C46.3932 64.1211 46.7508 64.5502 47.323 64.5502C47.8952 64.5502 48.2529 64.1211 48.4674 63.6204L67.4218 25.4972C68.7807 29.0735 69.496 32.8643 69.496 36.8698C69.3529 54.6797 54.7617 69.3424 36.9518 69.3424ZM36.9518 29.5026C36.7372 29.5026 36.6657 29.2881 36.5227 29.0735L26.366 6.18526C29.6562 4.96932 33.2325 4.32559 36.9518 4.32559C40.6711 4.32559 44.2474 4.96932 47.5376 6.18526L37.381 29.0735C37.2379 29.3596 37.1664 29.5026 36.9518 29.5026ZM26.5806 53.0346C26.366 53.0346 26.2945 52.82 26.1514 52.6054L9.70052 19.4175C12.6331 14.9114 16.567 11.1205 21.3592 8.47408L33.2325 34.8671C33.3755 35.2962 33.7332 35.4393 34.0908 35.4393H39.8128C40.242 35.4393 40.5281 35.3677 40.7427 34.8671L52.6159 8.47408C57.3366 11.1205 61.3421 14.9114 64.2746 19.4175L47.6807 52.6054C47.6091 52.82 47.4661 53.0346 47.2515 53.0346C47.0369 53.0346 46.9654 52.82 46.8224 52.6054L40.5996 38.4433C40.385 37.9427 40.0989 37.8711 39.6698 37.8711H33.9477C33.5186 37.8711 33.2325 37.9427 33.0179 38.4433L27.0097 52.6054C26.9382 52.82 26.7952 53.0346 26.5806 53.0346ZM36.9518 72.6326C56.7644 72.6326 72.7147 56.6824 72.7147 36.8698C72.7147 17.0572 56.7644 1.10693 36.9518 1.10693C17.1392 1.10693 1.18896 17.0572 1.18896 36.8698C1.18896 56.6824 17.1392 72.6326 36.9518 72.6326Z" fill="#001E50"/>
    </g>
    <defs>
    <clipPath id="clip0_5_5139">
    <rect width="71.888" height="71.888" fill="white" transform="translate(0.964355 0.97168)"/>
    </clipPath>
    </defs>
    </svg>
    
}