/* eslint-disable jsx-a11y/alt-text */
import { useHistory } from "react-router-dom";
import BaseInput from "../../../components/baseInput";
import BaseSelect from "../../../components/baseSelect";
import { YellowBtn } from "../../../components/yellowBtn";
import { ApplyFormObj, formInputPros, ListProps } from "../../../includes/types";
import { NextBtnWithArrow } from "../../carDetails";
import Avatar  from "../../../assets/images/avatar.png"
import Provinces from "../../../json/provinces";
import Cities from "../../../json/cities";
export interface FormSectionProps {
    onValue:(d:formInputPros)=>void;
    onBack:(d:number)=>void;
    values:ApplyFormObj;
    loading?:boolean;
}
export const FormOne = (props:FormSectionProps)=>{
    const history = useHistory();
    var cities:ListProps[] = [];
    var selectedCity = "";
    var foundCity:ListProps | undefined = undefined;
    const found =  Provinces.find((a,i)=> a.title === props.values.province)
    if(found)
    {
     cities = Cities.filter((a,i)=>a.pcode === found.code).map((a,i)=>{
        return {
            value:a.title,
            title:a.title
        }
     });
    foundCity =  cities.find((a,i)=>a.title === props.values.city)
    if(foundCity)
    {
        selectedCity = foundCity.title;
    }else{
        selectedCity = "" 
    }
    }
    return  <div className="container py-5">
    <div className="row mt-5">
        <div className="col-lg-6">
                <button
                onClick={()=>{
                    history.goBack()
                }}
                className="btn p-0 m-0"
                >
                    <NextBtnWithArrow />
                </button>
         </div>
    </div>
<div className="text-center mt-5 anim_section">
    <div className="center mb-3">
    <div className="avatar">
        <img
    src={Avatar}
     style={{width:"100%",height:"100%",objectFit:"contain"}}
        />
    </div>
    </div>
    <div className="container">
        <div className="play fs-40 mo-fs-30" >
        Hey! I'm Olivia. <br/>I will get you started on your new car in minutes
        </div>
        <div className="fs-18 ff-inter black text-center mb-5 mt-3">
        Please tell us about your present location
        </div>
        <div className="row text-start mb-3">
        <div className="col-lg-6">
            <BaseSelect 
            label="COUNTRY"
            name="country"
            required
            option={[
                {title:"Canada",value:"Canada"}
            ]}
            onChange={(d)=>{
                
                props.onValue({key:"country",value:d.value});

            }}
            value={props.values.country!}
            />
        </div>
        <div className="col-lg-6">
        <BaseSelect 
            label="PROVINCE"
            name="province"
            option={Provinces.map((a,i)=>{
                return {
                   title:a.title,
                   value:a.title 
                }
            }).filter((a,i)=>a.title === "Ontario" || a.title === "British Columbia")}
            required
            onChange={(d)=>{
                props.onValue({key:"province",value:d.title})
            }}
            value={props.values.province!}
            />
        </div>
        </div>
        <div className="row text-start mb-3">
        <div className="col-lg-6">
            <BaseSelect 
            label="CITY"
            name="city"
            required
            option={cities}
            onChange={(d)=>{
             props.onValue({key:"city",value:d.title})
            }}
            value={selectedCity}
            />
        </div>
        <div className="col-lg-6">
        <BaseInput 
            label="POSTAL CODE"
            name="postCode"
            placeholder="Enter postal code"
            max={8}
            required
            onChange={(d)=>{
                props.onValue({key:"postCode",value:d.value})
            }}
            value={props.values.postCode!}
            type="text"
            />
        </div>
        </div>
        <div className="center">
        <div  style={{minWidth:320}}>
            <YellowBtn 
            filled
            type="submit"
            text="Proceed"
            >
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
            <path d="M16.672 10.9997L11.308 5.63568L12.722 4.22168L20.5 11.9997L12.722 19.7777L11.308 18.3637L16.672 12.9997H4.5L4.5 10.9997H16.672Z" fill="#212529"/>
            </svg>
            </YellowBtn>
        </div>
        </div>
    </div>
    </div>
    </div>
}
