import { useEffect } from "react";
import { SectionBlackComponent } from "../../components/footer/sectionTwo";
import SectionSixComponent from "../homePage/sections/sectionSix";
import SectionOneComponent from "./sections/sectionOne";
import SectionTwoComponent from "./sections/sectionTwo";
import HeaderComponent from "../../components/header";

const InvestorScreenComponent = ()=>{
    useEffect(()=>{
        window.scrollTo({top:0,behavior:"smooth"})
    },[])
    return <>
    <HeaderComponent  />
    <div className="neutral">
   <SectionOneComponent 
     
       />
    <SectionTwoComponent />
    <SectionSixComponent />  
    <SectionBlackComponent />
    </div>
    </>
    
}
export default InvestorScreenComponent;