import { useState } from "react";
import { APIResponse, ApplyFormObj } from "./types";
import { GetDATA, PostDATA } from "./functions";

const useHttpHook = ()=>{
 
    const [loading,setLoading] = useState<boolean>(false);
    const HandleApplySubmit = (props:ApplyFormObj)=>{
        return new Promise<APIResponse>((resolve)=>{
         setLoading(true)
         const data = props;
         delete data.livingStatus;
         delete data.workStatus;
         delete data.downPayment;
         data.phoneNumber = String(data.dailCode)+parseInt(String(data.phoneNumber));
         delete data.dailCode;

        return PostDATA("application",{
            ...data,
            knowsCreditScore:String(props.knowsCreditScore).toLowerCase() === "yes",
            aboveAge:String(props.aboveAge).toLowerCase() === "yes",
            hasDriverLicense:String(props.hasDriverLicense).toLowerCase() === "yes",
            needHelpGettingDriverLicence:String(props.needHelpGettingDriverLicence).toLowerCase() === "yes",
        }).then((response)=>{
            setLoading(false)
            resolve(response)
        })
        })
    }
    const HandleInvestorsSubmit  = (props:ApplyFormObj)=>{
        return new Promise<APIResponse>((resolve)=>{
            setLoading(true)
        return PostDATA("inquiry",props).then((response)=>{
            setLoading(false)
            resolve(response)
        })
        })
    }
    const GetCars  = (props:ApplyFormObj)=>{
        return new Promise<APIResponse>((resolve)=>{
            setLoading(true)
            
        return GetDATA(`cars?itemsPerPage=20&pageNumber=1`,{}).then((response)=>{
            setLoading(false)
            resolve(response)
        })
        })
    }
    
    const GetCarDetails  = (id:string)=>{
        return new Promise<APIResponse>((resolve)=>{
            setLoading(true)
        return GetDATA("car/"+id,{}).then((response)=>{
            setLoading(false)
            resolve(response)
        })
        })
    }
    const GetInquiryTopic  = ()=>{
        return new Promise<APIResponse>((resolve)=>{
            setLoading(true)
        return GetDATA("inquiry-topics",{}).then((response)=>{
            setLoading(false)
            resolve(response)
        })
        })
    }
  
    return {
        GetCarDetails,
        loading,
        HandleApplySubmit,
        HandleInvestorsSubmit,
        GetCars,
        GetInquiryTopic
    }
}
export default useHttpHook;