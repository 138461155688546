interface YellowBtnProps {
    text:string;
    loading?:boolean;
    bold?:boolean;
    filled?:boolean;
    onClick?:()=>void;
    children?:JSX.Element;
    type?:"button"|"submit"
    width?:number;
}
export const YellowBtn = (props:YellowBtnProps)=>{
    return <button 
    onClick={props.onClick}
className={`${!props.loading ?"yellow":"inActive"}-btn ${props.filled && "btn-filled"}`} style={{width:props?.width?props.width:"auto"}}>
{props.loading?<div className="spinner-border text-warning" role="status"></div>:
<div className="btn-inner">
<div className="btnn">
<div >
{props.children}
{props.text}
</div>
<div >
{props.children}
{props.text}
</div>
</div>
    </div>}
    </button>
}
