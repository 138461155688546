export const GoogleIcon = ()=>{
    return <svg xmlns="http://www.w3.org/2000/svg" width="103" height="32" viewBox="0 0 103 32" fill="none">
    <g clipPath="url(#clip0_5_4579)">
      <path d="M13.5803 11.4182V14.8364H21.7985C21.5803 16.7273 20.9258 18.1818 19.9076 19.1273C18.7439 20.2909 16.853 21.6 13.5803 21.6C8.56212 21.6 4.63485 17.5273 4.63485 12.5091C4.63485 7.49091 8.48939 3.41818 13.5803 3.41818C16.2712 3.41818 18.3076 4.50909 19.7621 5.89091L22.1621 3.49091C20.1258 1.52727 17.3621 0 13.5803 0C6.67121 0 0.853027 5.6 0.853027 12.5091C0.853027 19.4182 6.67121 25.0182 13.5803 25.0182C17.2894 25.0182 20.1258 23.7818 22.3076 21.5273C24.5621 19.2727 25.2894 16.0727 25.2894 13.5273C25.2894 12.7273 25.2167 12 25.1439 11.4182H13.5803Z" fill="#4285F4"/>
      <path d="M35.4711 8.58185C31.0348 8.58185 27.3257 12 27.3257 16.6546C27.3257 21.3091 30.962 24.7273 35.4711 24.7273C39.9802 24.7273 43.6166 21.3818 43.6166 16.7273C43.6166 12 39.9802 8.58185 35.4711 8.58185ZM35.4711 21.6C32.9984 21.6 30.8893 19.5637 30.8893 16.7273C30.8893 13.8182 32.9984 11.8546 35.4711 11.8546C37.9439 11.8546 40.053 13.8182 40.053 16.7273C40.053 19.5637 37.9439 21.6 35.4711 21.6Z" fill="#EA4335"/>
      <path d="M75.2529 10.4H75.1075C74.3075 9.45458 72.7802 8.58185 70.8166 8.58185C66.7438 8.58185 63.2529 12.0728 63.2529 16.6546C63.2529 21.1637 66.7438 24.7273 70.8166 24.7273C72.7802 24.7273 74.3075 23.8546 75.1075 22.9091H75.2529V24.0728C75.2529 27.1273 73.5802 28.8 70.962 28.8C68.7802 28.8 67.4711 27.2728 66.8893 25.8909L63.8347 27.2C64.7075 29.3091 67.1075 32 71.0347 32C75.1802 32 78.7438 29.5273 78.7438 23.5637V9.01821H75.3984V10.4H75.2529ZM71.1802 21.6C68.7075 21.6 66.8893 19.4909 66.8893 16.7273C66.8893 13.8909 68.7802 11.8546 71.1802 11.8546C73.5802 11.8546 75.4711 13.9637 75.4711 16.8C75.5438 19.5637 73.6529 21.6 71.1802 21.6Z" fill="#4285F4"/>
      <path d="M53.5805 8.58185C49.1442 8.58185 45.4351 12 45.4351 16.6546C45.4351 21.3091 49.0714 24.7273 53.5805 24.7273C58.0896 24.7273 61.726 21.3818 61.726 16.7273C61.726 12 58.0896 8.58185 53.5805 8.58185ZM53.5805 21.6C51.1078 21.6 48.9987 19.5637 48.9987 16.7273C48.9987 13.8182 51.1078 11.8546 53.5805 11.8546C56.0532 11.8546 58.1623 13.8182 58.1623 16.7273C58.1623 19.5637 56.0532 21.6 53.5805 21.6Z" fill="#FBBC05"/>
      <path d="M81.4351 0.363647H84.926V24.8H81.4351V0.363647Z" fill="#34A853"/>
      <path d="M95.6894 21.6C93.8713 21.6 92.6349 20.8 91.7622 19.1273L102.599 14.6909L102.235 13.7455C101.58 11.9273 99.4713 8.58185 95.3258 8.58185C91.1804 8.58185 87.6895 11.8546 87.6895 16.6546C87.6895 21.1637 91.1076 24.7273 95.6894 24.7273C99.3985 24.7273 101.508 22.4728 102.453 21.1637L99.6894 19.3455C98.744 20.7273 97.5076 21.6 95.6894 21.6ZM95.4713 11.6364C96.9258 11.6364 98.1622 12.3637 98.5258 13.3818L91.2531 16.4364C91.1804 13.0909 93.6531 11.6364 95.4713 11.6364Z" fill="#EA4335"/>
    </g>
    <defs>
      <clipPath id="clip0_5_4579">
        <rect width="101.745" height="32" fill="white" transform="translate(0.853027)"/>
      </clipPath>
    </defs>
  </svg>
}