import BaseInput from "../../../components/baseInput";
import BaseSelect from "../../../components/baseSelect";
import { YellowBtn } from "../../../components/yellowBtn";
import { ReturnAllLetter } from "../../../includes/functions";
import { ApplyFormObj, formInputPros } from "../../../includes/types";
import { NextBtnWithArrow } from "../../carDetails";
import Avatar  from "../../../assets/images/avatar.png"
import { BaseCalendar } from "../../../components/baseCalendar";

export interface FormSectionProps {
    onValue:(d:formInputPros)=>void;
    onBack:(d:number)=>void;
    values:ApplyFormObj;
    loading?:boolean;
}
export const FormTwo = (props:FormSectionProps)=>{
    return  <div className="container py-5">
    <div className="row mt-5">
        <div className="col-lg-6">
                <button
                onClick={()=>{
                   props.onBack(0)
                }}
                className="btn p-0 m-0"
                >
                    <NextBtnWithArrow />
                </button>
         </div>
    </div>
<div className="text-center mt-5 anim_section">
    <div className="center mb-3">
    <div className="avatar">
    <img
    src={Avatar}
     style={{width:"100%",height:"100%",objectFit:"contain"}}
        />
    </div>
    </div>
    <div className="container">
        <div className="play fs-40 mo-fs-30" >
        Awesome! I'd like to meet you
        </div>
        <div className="fs-18 ff-inter black text-center mb-5 mt-3">
        Tell us about yourself
        </div>
        <div className="row text-start mb-3">
        <div className="col-lg-6">
            <BaseInput 
            label="FIRST NAME"
            name="firstName"
            required
            placeholder="e.g John"
            max={30}
            onChange={(d)=>{
                props.onValue({key:"firstName",value:ReturnAllLetter(d.value)})
            }}
            value={props.values.firstName!}
            type="text"
            />
        </div>
        <div className="col-lg-6">
        <BaseInput 
            label="LAST NAME"
            name="lastName"
            placeholder="e.g Doe"
            required
            max={30}
            onChange={(d)=>{
                props.onValue({key:"lastName",value:ReturnAllLetter(d.value)})
            }}
            value={props.values.lastName!}
            type="text"
            /></div>
        </div>
        <div className="row text-start mb-3">
        <div className="col-lg-12">
            <BaseSelect 
            label="ARE YOU OVER THE AGE OF 18?"
            name="city"
            required
            option={[
                {title:"Yes",value:"yes"},
                {title:"No",value:"No"}
            ]}
            onChange={(d)=>{
                props.onValue({key:"aboveAge",value:d.value})
            }}
            value={props.values.aboveAge!}
            />
        </div>
        </div>
        <div className="row text-start mb-3">
        <div className={`col-lg-${props.values.livingStatus === "Work Permit"?"6":"12"}`}>
        <BaseSelect
            label="WHAT'S YOUR LIVING STATUS IN CANADA?"
            name="workStatus"
            option={[
                {title:"Work Permit (if yes)",value:"Work Permit"},
                {title:"Expiration of WP",value:"Expiration of WP"},
                {title:"Permanent Residence",value:"Expiration of WP"},
                {title:"Citizen",value:"Citizen"}
            ]}
            required
            onChange={(d)=>{
                props.onValue({key:"livingStatus",value:d.value})
            }}
            value={props.values.livingStatus!}
            />
        </div>
        {props.values.livingStatus === "Work Permit"?<div className={`col-lg-6`}>
        <BaseCalendar
            label="EXPIRATION OF WORK PERMIT"
            name="workStatus"
            required
            onChange={(d)=>{
                props.onValue({key:"typeOfArrangement",value:d.value})
            }}
            value={props.values.typeOfArrangement!}
            placeholder="YYYY-MM-DD"
            />
        </div>:null}
        </div>
        <div className="row text-start mb-3">
        <div className="col-lg-12">
        <BaseSelect
            label="WORK STATUS"
            name="workStatus"
            option={[
                {title:"Employed",value:"Employed"},
                {title:"Self Employed",value:"Self Employed"}
            ]}
            required
            onChange={(d)=>{
                props.onValue({key:"workStatus",value:d.value})
            }}
            value={props.values.workStatus!}
            />
        </div>
        </div>
        <div className="center">
        <div  style={{minWidth:320}}>
            <YellowBtn 
            filled
            type="submit"
            text="Proceed"
            >
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
            <path d="M16.672 10.9997L11.308 5.63568L12.722 4.22168L20.5 11.9997L12.722 19.7777L11.308 18.3637L16.672 12.9997H4.5L4.5 10.9997H16.672Z" fill="#212529"/>
            </svg>
            </YellowBtn>
        </div>
        </div>
    </div>
    </div>
    </div>
}
