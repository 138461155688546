/* eslint-disable import/first */
import { HTMLInputTypeAttribute, RefObject, useEffect, useRef, useState } from "react";
import "./style.css";
import { ItemProps, formInputPros } from "../../includes/types";
import { ReturnAllNumbers } from "../../includes/functions";
interface BaseInputProps {
onChange:(d:formInputPros)=>void;
value:string;
max?:number;
type:HTMLInputTypeAttribute | undefined 
label?:string;
placeholder?:string;
required?:boolean;
name:string;
onCode?:(code:string)=>void;
}
import {Countries}  from "../../json/country-by-flag";
const BaseInput = (props:BaseInputProps)=>{
    const [show,setShow] = useState<boolean>(false);
    const [flag,setFlag] = useState<string>("");
    const [code,setCode] = useState<string>("");
    const thisView = useRef() as RefObject<HTMLDivElement>
    const [list,setList] = useState<ItemProps[]>([])
    useEffect(()=>{
        thisView.current?.addEventListener("mouseleave",()=>{
            setShow(false)
        })
    },[props.value,show])
    useEffect(()=>{
        const data = Countries.map((a:any)=>{
            return {
                title:`${a.name} (+${a.calling_code})`,
                value:a.calling_code,
                flag:`${a.flag}`,
                slug:a.name
            }
        })
        setList(data)
        const getFlag = data.find((a:any)=>a.slug === "Canada")
        if(getFlag)
            {
                setFlag(getFlag.flag)
                setCode(getFlag.value)
            }

    },[])
    return <div className="mb-3"
    ref={thisView}

    >
    {props?.label &&<label className="baseInput-label ms-2">{props.label}</label>}
    <div className="center" >
    {props.type === "tel" && <div 
    onClick={()=>setShow(!show)}
    className="center baseInput float-left" >
        <img  className="flag me-2"
        src={`data:image/png;base64,${flag}`}
        />
        +{code}
<svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.1004 7.45825L11.6671 12.8916C11.0254 13.5333 9.97539 13.5333 9.33372 12.8916L3.90039 7.45825" fill="#D4D4D4"/>
<path d="M17.1004 7.45825L11.6671 12.8916C11.0254 13.5333 9.97539 13.5333 9.33372 12.8916L3.90039 7.45825" stroke="#D4D4D4" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
</div>}
    <div className="baseInput float-right full-width">
        
        <input
        maxLength={props.max}
        onChange={(d)=>{
            var value = d.target.value;
            if(props.type === "number"|| props.type === "tel" )
                {
                    value = ReturnAllNumbers(value)
                }
            props.onChange({key:props.name,value:value})
        }}
        value={props.value}
        required={props.required}
        type={props.type === "number" || props.type === "tel" ?"text":props.type}
        placeholder={props.placeholder}
        />
    </div>
   
    </div>
    <div className="p-rel">
    <div className="drop-down p-abs" >
    {show && props.type === "tel"?<ul 
    className="no-deco p-0 m-0"
    >
    {list.map((a,i)=><li 
    onClick={()=>{
        if(props.onCode)
        {
        props.onCode(a.value)
        }
        setFlag(`${a.flag}`)
        setCode(a.value);
        setShow(false)
    }} 
    key={i}
    >{a.title}</li>)}
    </ul>:null}
    </div>
    </div>
    </div>
}
export default BaseInput;