export const AudiLogo = ()=>{
    return <svg width="117" height="118" viewBox="0 0 117 118" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_5_5097)">
    <g clipPath="url(#clip0_5_5097)">
    <path d="M35.1067 44.9038C32.2218 45 29.4903 46.226 27.5009 48.3174C25.5115 50.4089 24.4236 53.1983 24.4717 56.0844C24.5199 58.9705 25.7001 61.7221 27.7581 63.746C29.8161 65.77 32.587 66.9042 35.4735 66.9042C38.36 66.9042 41.1309 65.77 43.1889 63.746C45.2468 61.7221 46.4271 58.9705 46.4752 56.0844C46.5233 53.1983 45.4355 50.4089 43.4461 48.3174C41.4567 46.226 38.7251 45 35.8403 44.9038H35.1067ZM50.5113 44.9038C47.6264 45 44.8949 46.226 42.9055 48.3174C40.9161 50.4089 39.8282 53.1983 39.8763 56.0844C39.9244 58.9705 41.1047 61.7221 43.1627 63.746C45.2207 65.77 47.9916 66.9042 50.8781 66.9042C53.7645 66.9042 56.5354 65.77 58.5934 63.746C60.6514 61.7221 61.8317 58.9705 61.8798 56.0844C61.9279 53.1983 60.84 50.4089 58.8507 48.3174C56.8613 46.226 54.1297 45 51.2448 44.9038H50.5113ZM65.9159 44.9038C63.031 45 60.2994 46.226 58.3101 48.3174C56.3207 50.4089 55.2328 53.1983 55.2809 56.0844C55.329 58.9705 56.5093 61.7221 58.5673 63.746C60.6253 65.77 63.3962 66.9042 66.2826 66.9042C69.1691 66.9042 71.94 65.77 73.998 63.746C76.056 61.7221 77.2363 58.9705 77.2844 56.0844C77.3325 53.1983 76.2446 50.4089 74.2552 48.3174C72.2658 46.226 69.5343 45 66.6494 44.9038H65.9159ZM81.3204 44.9038C78.4356 45 75.704 46.226 73.7146 48.3174C71.7253 50.4089 70.6374 53.1983 70.6855 56.0844C70.7336 58.9705 71.9139 61.7221 73.9719 63.746C76.0298 65.77 78.8007 66.9042 81.6872 66.9042C84.5737 66.9042 87.3446 65.77 89.4026 63.746C91.4606 61.7221 92.6409 58.9705 92.689 56.0844C92.7371 53.1983 91.6492 50.4089 89.6598 48.3174C87.6704 46.226 84.9389 45 82.054 44.9038H81.3204Z" stroke="black" strokeWidth="2.2465"/>
    </g>
    </g>
    <defs>
    <filter id="filter0_d_5_5097" x="0.171061" y="0.753499" width="116.818" height="116.818" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="2.2465"/>
    <feGaussianBlur stdDeviation="2.2465"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5_5097"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5_5097" result="shape"/>
    </filter>
    <clipPath id="clip0_5_5097">
    <rect width="71.888" height="24.9407" fill="white" transform="translate(22.6362 43.437)"/>
    </clipPath>
    </defs>
    </svg>
    
}