import BaseInput from "../../../components/baseInput";
import BaseSelect from "../../../components/baseSelect";
import { YellowBtn } from "../../../components/yellowBtn";
import { ReturnAllLetter, ReturnMobile } from "../../../includes/functions";
import { ApplyFormObj, formInputPros } from "../../../includes/types";
import { NextBtnWithArrow } from "../../carDetails";
import Avatar  from "../../../assets/images/avatar.png"

export interface FormSectionProps {
    onValue:(d:formInputPros)=>void;
    onBack:(d:number)=>void;
    values:ApplyFormObj;
    loading?:boolean;
}
export const FormThree = (props:FormSectionProps)=>{
    return  <div className="container py-5">
    <div className="row mt-5">
        <div className="col-lg-6">
                <button
                onClick={()=>{
                   props.onBack(1)
                }}
                className="btn p-0 m-0"
                >
                    <NextBtnWithArrow />
                </button>
         </div>
    </div>
<div className="text-center mt-5">
    <div className="center mb-3">
    <div className="avatar">
    <img
    src={Avatar}
     style={{width:"100%",height:"100%",objectFit:"contain"}}
        />
    </div>
    </div>
    <div className="container">
        <div className="play fs-40 mo-fs-30" >
        Awesome! Glad to meet you
        </div>
        <div className="fs-18 ff-inter black text-center mb-5 mt-3">
        How can we contact you?
        </div>
        <div className="px-5 mm-0 mp-0" >
        <div className="row text-start mb-3 ">
        <div className="col-lg-12">
            <BaseInput 
            label="EMAIL"
            name="email"
            required
            placeholder="johndoe@email.com"
            max={30}
            onChange={(d)=>{
                props.onValue({key:"email",value:d.value})
            }}
            value={props.values.email!}
            type="email"
            />
        </div>
        </div>
        <div className="row text-start mb-3">
        <div className="col-lg-12">
            <BaseInput
            label="PHONE NUMBER"
            name="phoneNumber"
            placeholder="9000000000"
            onCode={(code)=>{
                props.onValue({key:"dailCode",value:code}) 
            }}
            max={10}
            required
            onChange={(d)=>{
                props.onValue({key:"phoneNumber",value:ReturnMobile(String(d.value))})
            }}
            value={props.values.phoneNumber!}
            type="tel"
            />
        </div>
        </div>
        </div>
        <div className="center">
        <div  style={{minWidth:320}}>
            <YellowBtn 
            filled
            type="submit"
            text="Proceed"
            >
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
            <path d="M16.672 10.9997L11.308 5.63568L12.722 4.22168L20.5 11.9997L12.722 19.7777L11.308 18.3637L16.672 12.9997H4.5L4.5 10.9997H16.672Z" fill="#212529"/>
            </svg>
            </YellowBtn>
        </div>
        </div>
    </div>
    </div>
    </div>
}
