export const Manufacturers:{name:string}[] = JSON.parse(`[
{
	"name": "AC"
}, {
	"name": "AC PROPULSION"
}, {
	"name": "ACURA"
}, {
	"name": "A.D. TRAMONTANA"
}, {
	"name": "ALFA ROMEO"
}, {
	"name": "ALMAC"
}, {
	"name": "ALTERNATIVE CARS"
}, {
	"name": "AMUZA"
}, {
	"name": "ANTEROS"
}, {
	"name": "ARASH"
}, {
	"name": "ARIEL"
}, {
	"name": "ARRINERA"
}, {
	"name": "ASL"
}, {
	"name": "ASTERIO"
}, {
	"name": "ASTON MARTIN"
}, {
	"name": "AUDI"
}, {
	"name": "BAC"
}, {
	"name": "BAJAJ"
}, {
	"name": "BEIJING AUTOMOBILE WORKS"
}, {
	"name": "BENTLEY"
}, {
	"name": "BMW"
}, {
	"name": "BOLLORÉ"
}, {
	"name": "BOLWELL"
}, {
	"name": "BRILLIANCE / HUACHEN"
}, {
	"name": "BRISTOL"
}, {
	"name": "BRITISH LEYLAND"
}, {
	"name": "BRM BUGGY"
}, {
	"name": "BROOKE"
}, {
	"name": "BUDDY"
}, {
	"name": "BUFORI"
}, {
	"name": "BUGATTI"
}, {
	"name": "BUICK"
}, {
	"name": "BYD"
}, {
	"name": "CADILLAC"
}, {
	"name": "CAPARO"
}, {
	"name": "CARBONTECH"
}, {
	"name": "CARICE"
}, {
	"name": "CHANG'AN"
}, {
	"name": "CHANGHE"
}, {
	"name": "CHERY"
}, {
	"name": "CHEVROLET"
}, {
	"name": "CHEVRON"
}, {
	"name": "CITROËN"
}, {
	"name": "CHRYSLER"
}, {
	"name": "COMMUTER CARS"
}, {
	"name": "CONNAUGHT"
}, {
	"name": "COVINI"
}, {
	"name": "DACIA"
}, {
	"name": "DAIHATSU"
}, {
	"name": "DATSUN"
}, {
	"name": "DE LA CHAPELLE"
}, {
	"name": "DMC"
}, {
	"name": "DIARDI"
}, {
	"name": "DODGE"
}, {
	"name": "DONKERVOORT"
}, {
	"name": "DONGFENG"
}, {
	"name": "DONTO"
}, {
	"name": "DS AUTOMOBILES"
}, {
	"name": "DYNASTI ELECTRIC CAR CORP."
}, {
	"name": "E-VADE"
}, {
	"name": "EFFEDI"
}, {
	"name": "EGY-TECH ENGINEERING"
}, {
	"name": "ELECTRIC RACEABOUT"
}, {
	"name": "ELFIN"
}, {
	"name": "EMGRAND"
}, {
	"name": "ENGLON"
}, {
	"name": "ETERNITI"
}, {
	"name": "ETOX"
}, {
	"name": "EQUUS"
}, {
	"name": "EXAGON"
}, {
	"name": "FARALLI & MAZZANTI"
}, {
	"name": "FAW"
}, {
	"name": "FERRARI"
}, {
	"name": "FIAT"
}, {
	"name": "FISKER"
}, {
	"name": "FODAY"
}, {
	"name": "FORCE"
}, {
	"name": "FORD"
}, {
	"name": "FORD AUSTRALIA"
}, {
	"name": "FORD GERMANY"
}, {
	"name": "FORNASARI"
}, {
	"name": "FRASER"
}, {
	"name": "GAC GROUP"
}, {
	"name": "GALPIN"
}, {
	"name": "GEELY"
}, {
	"name": "GENESIS"
}, {
	"name": "GIBBS"
}, {
	"name": "GILLET"
}, {
	"name": "GINETTA"
}, {
	"name": "GMC"
}, {
	"name": "GONOW"
}, {
	"name": "GREAT WALL / CHANGCHENG"
}, {
	"name": "GREENTECH AUTOMOTIVE"
}, {
	"name": "GRINNALL"
}, {
	"name": "GTA MOTOR"
}, {
	"name": "GUMPERT"
}, {
	"name": "GURGEL"
}, {
	"name": "HENNESSEY"
}, {
	"name": "HINDUSTAN"
}, {
	"name": "HOLDEN"
}, {
	"name": "HONDA"
}, {
	"name": "HONGQI"
}, {
	"name": "HRADYESH"
}, {
	"name": "HTT TECHNOLOGIES"
}, {
	"name": "HULME"
}, {
	"name": "HYUNDAI"
}, {
	"name": "ICML"
}, {
	"name": "IFR"
}, {
	"name": "IRAN KHODRO"
}, {
	"name": "IKCO"
}, {
	"name": "IMPERIA"
}, {
	"name": "INFINITI"
}, {
	"name": "IVM"
}, {
	"name": "INVICTA"
}, {
	"name": "ISDERA"
}, {
	"name": "ISUZU"
}, {
	"name": "JAC"
}, {
	"name": "JAGUAR"
}, {
	"name": "JEEP"
}, {
	"name": "JENSEN MOTORS"
}, {
	"name": "JETCAR"
}, {
	"name": "JONWAY"
}, {
	"name": "JOSS"
}, {
	"name": "KAIPAN"
}, {
	"name": "KANTANKA"
}, {
	"name": "KARMA"
}, {
	"name": "KOENIGSEGG"
}, {
	"name": "KORRES"
}, {
	"name": "KIA"
}, {
	"name": "KIAT"
}, {
	"name": "KISH KHODRO"
}, {
	"name": "KTM"
}, {
	"name": "LADA"
}, {
	"name": "LAMBORGHINI"
}, {
	"name": "LANCIA"
}, {
	"name": "LAND ROVER"
}, {
	"name": "LANDWIND"
}, {
	"name": "LARAKI"
}, {
	"name": "LEBLANC"
}, {
	"name": "LEITCH"
}, {
	"name": "LEOPARD"
}, {
	"name": "LEXUS"
}, {
	"name": "LI-ION"
}, {
	"name": "LIFAN"
}, {
	"name": "LIGHTNING"
}, {
	"name": "LINCOLN"
}, {
	"name": "LISTER"
}, {
	"name": "LOCAL MOTORS"
}, {
	"name": "LOBINI"
}, {
	"name": "LOTEC"
}, {
	"name": "LOTUS CARS"
}, {
	"name": "LUCRA CARS"
}, {
	"name": "LUXGEN"
}, {
	"name": "MAHINDRA"
}, {
	"name": "MARUSSIA"
}, {
	"name": "MARUTI SUZUKI"
}, {
	"name": "MASERATI"
}, {
	"name": "MASTRETTA"
}, {
	"name": "MAZDA"
}, {
	"name": "MCLAREN"
}, {
	"name": "MERCEDES-BENZ"
}, {
	"name": "MG"
}, {
	"name": "MICRO"
}, {
	"name": "MINI"
}, {
	"name": "MITSUBISHI"
}, {
	"name": "MITSUOKA"
}, {
	"name": "MORGAN"
}, {
	"name": "MULLEN"
}, {
	"name": "MYCAR"
}, {
	"name": "MYVI-PERODUA"
}, {
	"name": "NISSAN"
}, {
	"name": "NOBLE"
}, {
	"name": "NOTA"
}, {
	"name": "OLDSMOBILE"
}, {
	"name": "OPEL"
}, {
	"name": "OPTIMAL ENERGY"
}, {
	"name": "ORCA"
}, {
	"name": "OLTCIT"
}, {
	"name": "PAGANI"
}, {
	"name": "PANHARD"
}, {
	"name": "PANOZ"
}, {
	"name": "PERANA"
}, {
	"name": "PERODUA"
}, {
	"name": "PEUGEOT"
}, {
	"name": "P.G.O."
}, {
	"name": "POLARSUN"
}, {
	"name": "PLYMOUTH"
}, {
	"name": "PORSCHE"
}, {
	"name": "PROTO"
}, {
	"name": "OULLIM"
}, {
	"name": "PROTON"
}, {
	"name": "PURITALIA"
}, {
	"name": "QOROS"
}, {
	"name": "QVALE"
}, {
	"name": "RADICAL"
}, {
	"name": "RELIANT"
}, {
	"name": "RENAULT"
}, {
	"name": "REVA"
}, {
	"name": "RIMAC"
}, {
	"name": "RINSPEED"
}, {
	"name": "RODING"
}, {
	"name": "ROEWE"
}, {
	"name": "ROLLS-ROYCE"
}, {
	"name": "ROSSIN-BERTIN"
}, {
	"name": "ROSSION"
}, {
	"name": "ROVER"
}, {
	"name": "SAAB"
}, {
	"name": "SALEEN"
}, {
	"name": "SAIC-GM-WULING"
}, {
	"name": "SAIPA"
}, {
	"name": "SAKER"
}, {
	"name": "SAMSUNG"
}, {
	"name": "SAN"
}, {
	"name": "SBARRO"
}, {
	"name": "SCION"
}, {
	"name": "SEAT"
}, {
	"name": "SHANGHAI MAPLE"
}, {
	"name": "SIN"
}, {
	"name": "ŠKODA"
}, {
	"name": "SMART"
}, {
	"name": "SPADA VETTURE SPORT"
}, {
	"name": "SPYKER"
}, {
	"name": "SSANGYONG"
}, {
	"name": "SSC NORTH AMERICA"
}, {
	"name": "STREET & RACING TECHNOLOGY"
}, {
	"name": "SUBARU"
}, {
	"name": "SUZUKI"
}, {
	"name": "TANOM"
}, {
	"name": "TATA"
}, {
	"name": "TAURO"
}, {
	"name": "TAWON CAR"
}, {
	"name": "TD CARS"
}, {
	"name": "TESLA"
}, {
	"name": "THAI RUNG"
}, {
	"name": "TOYOTA"
}, {
	"name": "TREKKA"
}, {
	"name": "TRIDENT"
}, {
	"name": "TRIUMPH"
}, {
	"name": "TROLLER"
}, {
	"name": "TRUMPCHI"
}, {
	"name": "TUSHEK"
}, {
	"name": "TVR"
}, {
	"name": "TVS"
}, {
	"name": "ULTIMA"
}, {
	"name": "UMM"
}, {
	"name": "UEV"
}, {
	"name": "URI"
}, {
	"name": "UAZ"
}, {
	"name": "VAUXHALL MOTORS"
}, {
	"name": "VECTOR"
}, {
	"name": "VENCER"
}, {
	"name": "VENIRAUTO"
}, {
	"name": "VENTURI"
}, {
	"name": "VEPR"
}, {
	"name": "VOLKSWAGEN"
}, {
	"name": "VOLVO"
}, {
	"name": "VINFAST"
}, {
	"name": "W MOTORS"
}, {
	"name": "WALLYSCAR"
}, {
	"name": "WESTFIELD"
}, {
	"name": "WHEEGO"
}, {
	"name": "WIESMANN"
}, {
	"name": "XENIA"
}, {
	"name": "YES!"
}, {
	"name": "YOUABIAN PUMA"
}, {
	"name": "ZASTAVA AUTOMOBILES"
}, {
	"name": "ZENDER CARS"
}, {
	"name": "ZENOS CARS"
}, {
	"name": "ZENVO"
}, {
	"name": "ZIL"
}, {
	"name": "ZX AUTO"
}]`)