export const BMWLOgo = ()=>{
    return <svg width="55" height="55" viewBox="0 0 73 73" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_5_5101)">
    <path d="M36.4153 1.03223C16.5898 1.03223 0.516602 17.1038 0.516602 36.9301C0.516602 56.756 16.5898 72.8279 36.4153 72.8279C56.2422 72.8279 72.3138 56.756 72.3138 36.9301C72.3138 17.1038 56.2423 1.03223 36.4153 1.03223ZM36.4153 71.0329C17.58 71.0329 2.31309 55.7647 2.31309 36.9301C2.31309 18.0956 17.58 2.82667 36.4153 2.82667C55.2504 2.82667 70.5186 18.0955 70.5186 36.9301C70.5186 55.7647 55.2504 71.0329 36.4153 71.0329Z" fill="url(#paint0_linear_5_5101)"/>
    <path d="M70.7754 36.9297C70.7754 55.9049 55.3901 71.2895 36.4148 71.2895C17.4393 71.2895 2.05566 55.9049 2.05566 36.9297C2.05566 17.9534 17.4393 2.56982 36.4148 2.56982C55.3901 2.56982 70.7754 17.9534 70.7754 36.9297Z" fill="#333333"/>
    <path d="M70.519 36.9296C70.519 55.7642 55.2508 71.0324 36.4157 71.0324C17.5804 71.0324 2.31348 55.7642 2.31348 36.9296C2.31348 18.0951 17.5804 2.82617 36.4157 2.82617C55.2508 2.82617 70.519 18.0951 70.519 36.9296Z" fill="url(#paint1_radial_5_5101)"/>
    <path d="M59.2787 36.9291C59.2787 49.5559 49.0423 59.7918 36.4151 59.7918C23.7877 59.7918 13.5513 49.5559 13.5513 36.9291C13.5513 24.3016 23.7877 14.0654 36.4151 14.0654C49.0423 14.0654 59.2787 24.3017 59.2787 36.9291Z" fill="url(#paint2_linear_5_5101)"/>
    <path d="M37.3304 13.4478L39.7055 7.15303V13.4478H41.5093V4.43262H38.7985L36.3328 10.8874H36.3562L33.8906 4.43262H31.1797V13.4478H32.9835V7.15303L35.3585 13.4478H37.3304Z" fill="black"/>
    <path d="M62.0856 17.49L59.0037 21.9837L63.8445 19.6029L65.1574 21.1774L57.5388 25.0028L55.9993 23.1391L58.9526 18.6863L58.9351 18.6644L54.0229 20.7669L52.4658 18.9157L57.6029 12.1133L58.9162 13.6887L55.6995 18.0203L60.6776 15.8L62.0856 17.49Z" fill="black"/>
    <path d="M20.449 15.7677C19.723 15.0629 18.6027 14.8504 17.5495 15.5033C17.7511 14.9818 17.6693 14.4509 17.5627 14.1565C17.1084 13.3305 16.9492 13.2261 16.5783 12.9127C15.3674 11.8982 14.0936 12.7645 13.1836 13.849L8.78418 19.0921L15.7004 24.8945L20.3395 19.3667C21.3998 18.1033 21.5635 16.8508 20.449 15.7677ZM11.5023 18.2961L14.1448 15.1469C14.4515 14.7825 15.1439 14.7971 15.5879 15.1711C16.0772 15.5815 16.0786 16.1628 15.75 16.555L13.1368 19.6678L11.5023 18.2961ZM18.7926 18.8197L16.0378 22.1027L14.3128 20.6529L17.0881 17.3446C17.4022 16.9706 18.1019 16.8618 18.5736 17.2575C19.1052 17.7046 19.1577 18.3845 18.7926 18.8197Z" fill="#333333"/>
    <path d="M37.3498 13.1001L39.7247 6.80552V13.1001H41.5287V4.08496H38.8176L36.3521 10.5395H36.3754L33.9099 4.08496H31.1987V13.1001H33.0027V6.80552L35.3779 13.1001H37.3498Z" fill="url(#paint3_radial_5_5101)"/>
    <path d="M62.1038 16.8859L59.0231 21.3798L63.8638 18.9982L65.1755 20.5735L57.558 24.3989L56.0186 22.5344L58.972 18.0823L58.9546 18.0597L54.0408 20.163L52.4854 18.3109L57.6225 11.5093L58.9355 13.084L55.7193 17.4156L60.6955 15.1961L62.1038 16.8859Z" fill="url(#paint4_radial_5_5101)"/>
    <path d="M20.1254 15.5902C19.4008 14.8854 18.279 14.6728 17.2275 15.3251C17.4275 14.8035 17.3457 14.2726 17.2392 13.9797C16.7849 13.1523 16.6271 13.0493 16.2531 12.7353C15.0423 11.7202 13.77 12.5869 12.8615 13.6724L8.46045 18.9146L15.3768 24.7171L20.0158 19.1885C21.0749 17.925 21.2398 16.6733 20.1254 15.5902ZM11.1789 18.1185L13.8212 14.9701C14.1266 14.605 14.8188 14.6196 15.2643 14.9927C15.7536 15.4039 15.7551 15.9859 15.4249 16.3767L12.8134 19.4901L11.1789 18.1185ZM18.4705 18.6422L15.7157 21.9251L13.9877 20.4753L16.7645 17.1662C17.0785 16.793 17.7767 16.6842 18.2486 17.0801C18.7831 17.5277 18.8357 18.2062 18.4705 18.6422Z" fill="url(#paint5_radial_5_5101)"/>
    <path d="M14.3125 36.9291C14.3125 24.7217 24.2085 14.8257 36.4153 14.8257C48.6233 14.8257 58.518 24.7217 58.518 36.9291C58.518 49.136 48.6233 59.0317 36.4153 59.0317C24.2085 59.0317 14.3125 49.136 14.3125 36.9291Z" fill="url(#paint6_radial_5_5101)"/>
    <path d="M14.9478 36.93C14.9478 25.0739 24.5589 15.4619 36.4151 15.4619C48.2714 15.4619 57.8838 25.0738 57.8838 36.93C57.8838 48.7863 48.2713 58.3982 36.4151 58.3982C24.559 58.3982 14.9478 48.7863 14.9478 36.93Z" fill="url(#paint7_radial_5_5101)"/>
    <path d="M14.9136 36.9301H36.4145V15.4292C24.5393 15.4292 14.9136 25.055 14.9136 36.9301Z" fill="url(#paint8_radial_5_5101)"/>
    <path d="M36.4155 36.9302V58.4314C48.2906 58.4314 57.9165 48.8038 57.9165 36.9302H36.4155Z" fill="url(#paint9_radial_5_5101)"/>
    <path d="M58.094 36.5483H36.7962V15.2505H36.0337V36.5483H14.7358V37.3109H36.0337V58.6094H36.7962V37.3109H58.094V36.5483Z" fill="url(#paint10_radial_5_5101)"/>
    <path d="M36.796 15.8531C48.1381 16.0548 57.2905 25.2065 57.4935 36.5477H57.8731C57.6703 24.9961 48.3483 15.6728 36.7959 15.4712V15.8531H36.796Z" fill="url(#paint11_linear_5_5101)"/>
    <path d="M15.2992 36.5451C15.5007 25.1884 24.6663 16.0235 36.0231 15.8212V15.4399C24.4547 15.6422 15.121 24.978 14.918 36.545H15.2992V36.5451Z" fill="#C2D7E8"/>
    <path d="M36.0332 58.0059C24.6913 57.8044 15.5388 48.652 15.3372 37.3101H14.9561C15.159 48.8623 24.481 58.1859 36.0333 58.3873V58.0059H36.0332Z" fill="url(#paint12_linear_5_5101)"/>
    <path d="M57.4934 37.3101C57.2904 48.652 48.1379 57.8044 36.7959 58.0059V58.3873C48.3483 58.1859 57.6715 48.8624 57.8731 37.3101H57.4934Z" fill="#12404F"/>
    <path d="M35.6264 15.4399V36.1295H14.9375V36.5377H35.6264H36.0338V36.1295V15.4399H35.6264Z" fill="url(#paint13_linear_5_5101)"/>
    <path d="M35.5917 58.1874V37.7468H15.1089V37.3438H35.5917H35.9947V37.7468V58.1874H35.5917Z" fill="url(#paint14_linear_5_5101)"/>
    <path d="M37.2328 58.1874V37.7468H57.6732V37.3438H37.2328H36.8311V37.7468V58.1874H37.2328Z" fill="url(#paint15_linear_5_5101)"/>
    <path d="M37.2328 15.6226V36.063H57.6732V36.4654H37.2328H36.8311V36.063V15.6226H37.2328Z" fill="url(#paint16_linear_5_5101)"/>
    </g>
    <defs>
    <linearGradient id="paint0_linear_5_5101" x1="-4.73843" y1="-2.83828" x2="71.4308" y2="70.7663" gradientUnits="userSpaceOnUse">
    <stop stopColor="#DFF4FF"/>
    <stop offset="0.0984" stopColor="#D6EAF5"/>
    <stop offset="0.2678" stopColor="#BFD0D9"/>
    <stop offset="0.4879" stopColor="#98A5AC"/>
    <stop offset="0.7469" stopColor="#646B6E"/>
    <stop offset="1" stopColor="#2B2B2B"/>
    </linearGradient>
    <radialGradient id="paint1_radial_5_5101" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(0.0695481 3.40595) scale(51.0021 51.0021)">
    <stop stopColor="white"/>
    <stop offset="0.3064" stopColor="#A8A8A8"/>
    <stop offset="0.6461" stopColor="#4F4F4F"/>
    <stop offset="0.8853" stopColor="#161616"/>
    <stop offset="1"/>
    </radialGradient>
    <linearGradient id="paint2_linear_5_5101" x1="62.6258" y1="62.2571" x2="14.114" y2="15.3786" gradientUnits="userSpaceOnUse">
    <stop stopColor="#DFF4FF"/>
    <stop offset="0.0855" stopColor="#D6EAF5"/>
    <stop offset="0.2327" stopColor="#BED0D9"/>
    <stop offset="0.4239" stopColor="#96A4AC"/>
    <stop offset="0.6516" stopColor="#5F686D"/>
    <stop offset="0.9075" stopColor="#1A1D1E"/>
    <stop offset="1"/>
    </linearGradient>
    <radialGradient id="paint3_radial_5_5101" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(-9.09103 -1.78754) scale(122.551 122.551)">
    <stop stopColor="white"/>
    <stop offset="0.3102" stopColor="#FDFDFD"/>
    <stop offset="0.4505" stopColor="#F6F6F6"/>
    <stop offset="0.5567" stopColor="#E9E9E9"/>
    <stop offset="0.6456" stopColor="#D7D7D7"/>
    <stop offset="0.7238" stopColor="#BFBFBF"/>
    <stop offset="0.7944" stopColor="#A2A2A2"/>
    <stop offset="0.8593" stopColor="#808080"/>
    <stop offset="0.9199" stopColor="#575757"/>
    <stop offset="0.9746" stopColor="#2B2B2B"/>
    <stop offset="1" stopColor="#141414"/>
    </radialGradient>
    <radialGradient id="paint4_radial_5_5101" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(-9.09628 -1.78763) scale(122.561 122.561)">
    <stop stopColor="white"/>
    <stop offset="0.3102" stopColor="#FDFDFD"/>
    <stop offset="0.4505" stopColor="#F6F6F6"/>
    <stop offset="0.5567" stopColor="#E9E9E9"/>
    <stop offset="0.6456" stopColor="#D7D7D7"/>
    <stop offset="0.7238" stopColor="#BFBFBF"/>
    <stop offset="0.7944" stopColor="#A2A2A2"/>
    <stop offset="0.8593" stopColor="#808080"/>
    <stop offset="0.9199" stopColor="#575757"/>
    <stop offset="0.9746" stopColor="#2B2B2B"/>
    <stop offset="1" stopColor="#141414"/>
    </radialGradient>
    <radialGradient id="paint5_radial_5_5101" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(-9.10334 -1.8246) scale(122.655 122.655)">
    <stop stopColor="white"/>
    <stop offset="0.3102" stopColor="#FDFDFD"/>
    <stop offset="0.4505" stopColor="#F6F6F6"/>
    <stop offset="0.5567" stopColor="#E9E9E9"/>
    <stop offset="0.6456" stopColor="#D7D7D7"/>
    <stop offset="0.7238" stopColor="#BFBFBF"/>
    <stop offset="0.7944" stopColor="#A2A2A2"/>
    <stop offset="0.8593" stopColor="#808080"/>
    <stop offset="0.9199" stopColor="#575757"/>
    <stop offset="0.9746" stopColor="#2B2B2B"/>
    <stop offset="1" stopColor="#141414"/>
    </radialGradient>
    <radialGradient id="paint6_radial_5_5101" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(12.8581 15.2014) scale(33.0556 33.0556)">
    <stop stopColor="#E6E6E6"/>
    <stop offset="0.1045" stopColor="#D6D6D6"/>
    <stop offset="0.3074" stopColor="#ADADAD"/>
    <stop offset="0.5884" stopColor="#6C6C6C"/>
    <stop offset="0.9335" stopColor="#121212"/>
    <stop offset="1"/>
    </radialGradient>
    <radialGradient id="paint7_radial_5_5101" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(21.8991 19.6483) scale(48.0723 48.0723)">
    <stop stopColor="white"/>
    <stop offset="0.3435" stopColor="#FDFDFD"/>
    <stop offset="0.4814" stopColor="#F6F6F6"/>
    <stop offset="0.5824" stopColor="#EAEAEA"/>
    <stop offset="0.6654" stopColor="#D8D8D8"/>
    <stop offset="0.7373" stopColor="#C2C2C2"/>
    <stop offset="0.8016" stopColor="#A6A6A6"/>
    <stop offset="0.8602" stopColor="#848484"/>
    <stop offset="0.9126" stopColor="#5F5F5F"/>
    <stop offset="0.9494" stopColor="#404040"/>
    <stop offset="0.9566" stopColor="#404040"/>
    <stop offset="1"/>
    </radialGradient>
    <radialGradient id="paint8_radial_5_5101" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(7.78386 1.42574) scale(85.1367)">
    <stop offset="0.1685" stopColor="white"/>
    <stop offset="0.1957" stopColor="#E0EFF7"/>
    <stop offset="0.2406" stopColor="#B2D9EC"/>
    <stop offset="0.2872" stopColor="#8BC5E2"/>
    <stop offset="0.335" stopColor="#6BB5DA"/>
    <stop offset="0.3844" stopColor="#52A9D4"/>
    <stop offset="0.4359" stopColor="#41A0CF"/>
    <stop offset="0.4911" stopColor="#369BCD"/>
    <stop offset="0.5562" stopColor="#3399CC"/>
    <stop offset="0.606" stopColor="#3396C8"/>
    <stop offset="0.6553" stopColor="#328EBC"/>
    <stop offset="0.7045" stopColor="#3180A8"/>
    <stop offset="0.7536" stopColor="#2F6D8C"/>
    <stop offset="0.8027" stopColor="#2D5468"/>
    <stop offset="0.8512" stopColor="#2A373D"/>
    <stop offset="0.8708" stopColor="#292929"/>
    </radialGradient>
    <radialGradient id="paint9_radial_5_5101" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(7.78485 1.42523) scale(85.135 85.135)">
    <stop offset="0.1685" stopColor="white"/>
    <stop offset="0.1957" stopColor="#E0EFF7"/>
    <stop offset="0.2406" stopColor="#B2D9EC"/>
    <stop offset="0.2872" stopColor="#8BC5E2"/>
    <stop offset="0.335" stopColor="#6BB5DA"/>
    <stop offset="0.3844" stopColor="#52A9D4"/>
    <stop offset="0.4359" stopColor="#41A0CF"/>
    <stop offset="0.4911" stopColor="#369BCD"/>
    <stop offset="0.5562" stopColor="#3399CC"/>
    <stop offset="0.606" stopColor="#3396C8"/>
    <stop offset="0.6553" stopColor="#328EBC"/>
    <stop offset="0.7045" stopColor="#3180A8"/>
    <stop offset="0.7536" stopColor="#2F6D8C"/>
    <stop offset="0.8027" stopColor="#2D5468"/>
    <stop offset="0.8512" stopColor="#2A373D"/>
    <stop offset="0.8708" stopColor="#292929"/>
    </radialGradient>
    <radialGradient id="paint10_radial_5_5101" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(13.3094 15.6191) scale(32.4221 32.4221)">
    <stop stopColor="#E6E6E6"/>
    <stop offset="0.1045" stopColor="#D6D6D6"/>
    <stop offset="0.3074" stopColor="#ADADAD"/>
    <stop offset="0.5884" stopColor="#6C6C6C"/>
    <stop offset="0.9335" stopColor="#121212"/>
    <stop offset="1"/>
    </radialGradient>
    <linearGradient id="paint11_linear_5_5101" x1="32.1635" y1="7.52312" x2="63.7814" y2="38.0764" gradientUnits="userSpaceOnUse">
    <stop stopColor="#F0F4FF"/>
    <stop offset="0.1086" stopColor="#EBEFF9"/>
    <stop offset="0.2475" stopColor="#DCE0EA"/>
    <stop offset="0.4028" stopColor="#C4C7CF"/>
    <stop offset="0.5701" stopColor="#A2A4AB"/>
    <stop offset="0.7469" stopColor="#76777C"/>
    <stop offset="0.9291" stopColor="#414243"/>
    <stop offset="1" stopColor="#2B2B2B"/>
    </linearGradient>
    <linearGradient id="paint12_linear_5_5101" x1="6.49974" y1="33.3187" x2="38.1182" y2="63.8725" gradientUnits="userSpaceOnUse">
    <stop stopColor="#F0F4FF"/>
    <stop offset="0.1086" stopColor="#EBEFF9"/>
    <stop offset="0.2475" stopColor="#DCE0EA"/>
    <stop offset="0.4028" stopColor="#C4C7CF"/>
    <stop offset="0.5701" stopColor="#A2A4AB"/>
    <stop offset="0.7469" stopColor="#76777C"/>
    <stop offset="0.9291" stopColor="#414243"/>
    <stop offset="1" stopColor="#2B2B2B"/>
    </linearGradient>
    <linearGradient id="paint13_linear_5_5101" x1="21.7966" y1="22.424" x2="38.192" y2="38.2673" gradientUnits="userSpaceOnUse">
    <stop stopColor="#C2D7E8"/>
    <stop offset="0.1337" stopColor="#BACFE1"/>
    <stop offset="0.3425" stopColor="#A4BACD"/>
    <stop offset="0.6001" stopColor="#8098AC"/>
    <stop offset="0.894" stopColor="#4E697F"/>
    <stop offset="1" stopColor="#3A566D"/>
    </linearGradient>
    <linearGradient id="paint14_linear_5_5101" x1="13.5858" y1="26.3059" x2="44.886" y2="56.5522" gradientUnits="userSpaceOnUse">
    <stop stopColor="#F0F4FF"/>
    <stop offset="0.0973" stopColor="#E8EBF6"/>
    <stop offset="0.2568" stopColor="#D1D4DD"/>
    <stop offset="0.4587" stopColor="#ABAEB5"/>
    <stop offset="0.6951" stopColor="#78797D"/>
    <stop offset="0.9578" stopColor="#363637"/>
    <stop offset="1" stopColor="#2B2B2B"/>
    </linearGradient>
    <linearGradient id="paint15_linear_5_5101" x1="36.6554" y1="37.5256" x2="47.6346" y2="48.1351" gradientUnits="userSpaceOnUse">
    <stop stopColor="#D9DEF0"/>
    <stop offset="0.1229" stopColor="#D4DAEC"/>
    <stop offset="0.2629" stopColor="#C7CFE2"/>
    <stop offset="0.4114" stopColor="#B0BCD1"/>
    <stop offset="0.5657" stopColor="#90A1B8"/>
    <stop offset="0.7246" stopColor="#677F99"/>
    <stop offset="0.8849" stopColor="#355674"/>
    <stop offset="0.9719" stopColor="#173D5D"/>
    </linearGradient>
    <linearGradient id="paint16_linear_5_5101" x1="25.4239" y1="14.8268" x2="56.6902" y2="45.0404" gradientUnits="userSpaceOnUse">
    <stop stopColor="#F0F4FF"/>
    <stop offset="0.1086" stopColor="#EBEFF9"/>
    <stop offset="0.2475" stopColor="#DCE0EA"/>
    <stop offset="0.4028" stopColor="#C4C7CF"/>
    <stop offset="0.5701" stopColor="#A2A4AB"/>
    <stop offset="0.7469" stopColor="#76777C"/>
    <stop offset="0.9291" stopColor="#414243"/>
    <stop offset="1" stopColor="#2B2B2B"/>
    </linearGradient>
    <clipPath id="clip0_5_5101">
    <rect width="71.888" height="71.888" fill="white" transform="translate(0.468262 0.97168)"/>
    </clipPath>
    </defs>
    </svg>
    
}