interface HorizontalLineProps {
    width?:string;
}
interface VericleLineProps {
    height?:string;
}
export const HorizontalLine = (props:HorizontalLineProps)=>{
    return <div className="horizontal-line" style={{width:props.width?props.width:"100%"}} ></div>
}
export const VericleLine = (props:VericleLineProps)=>{
    return <div className="vertical-line" style={{height:props.height?props.height:"100%"}} ></div>
}