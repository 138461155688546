const Provinces = [
    {code:"AB", title:"Alberta"},
    {code:"BC", title:"British Columbia"},
    {code:"MB", title:"Manitoba"},
    {code:"NB", title:"New Brunswick"},
    {code:"NL", title:"Newfoundland and Labrador"},
    {code:"NS", title:"Nova Scotia"},
    {code:"ON", title:"Ontario"},
    {code:"PE", title:"Prince Edward Island"},
    {code:"QC", title:"Quebec"},
    {code:"SK", title:"Saskatchewan"},
   ]
export default Provinces;